import React, { useRef, useState, useEffect} from 'react';
import ky from 'ky';
import EquipCard from '../../components/inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
//import items from '../utils/items';
import {setUser} from '../../slices/userSlice';
import {setTeam} from '../../slices/teamSlice';
import {setAvailableItems} from '../../slices/availableItems';
import {setScreenTab} from '../../slices/screenTabSlice';
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;



export default function Inventory() {
  const dispatch = useDispatch();
  const [karmaModal, setKarmaModal] = useState(0)
  const [changeItemTargetId, setChangeItemTargetId] = useState(0)
  const [showRoomTab, setShowRoomTab] = useState(0)

  const screenTab = useSelector((state:RootState)=> state.screenTab);

  const user = useSelector((state:RootState)=> state.user);
  const userLocations = useSelector((state:RootState)=> state.userLocations);
  const team = useSelector((state:RootState)=> state.team);
  const available_items = useSelector((state:RootState)=> state.availableItems);
  //const available_equips :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_hulls :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_crews :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  const available_equips = available_items?.filter((el:any) => el.item_type === "module")
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const link_id = urlParams.get('link_id');
  //function getItemJson(card_id:number, item_type:string){
  //  return available_items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  //useEffect(() => {
  //  fetchTeam()
  //}, [dispatch]);

  console.log('srav',user?.team?.id, team?.id)
  async function fetchTeam() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/teams/get_full_team?hash_id=${hash_id}`;

      const new_team :any = await ky.get(requestUrl, { credentials: 'include' }).json();
      if(new_team){
        dispatch(setTeam(new_team));
        console.log('new_team', new_team) // ??????????????????
        }
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };


  
  async function decreaseKarma() {

    const data = await ky.put(`${API_BASE_URL}/gameplay/teams/decrease_karma/?hash_id=${hash_id}`, { json: {} , credentials:'include'}).json();
    console.log("decreaseKarma response", data)
    fetchTeam()
  }

  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        return "■ ".repeat(square_num)
    }
    return ""


  }

  function karmaToProbability(karma: number){
    let k= 0.05
            
    let n = 0
    if (karma < 0){n = -karma }
        
    if(karma < -10){ n = 10}
    return Math.round(k*n*100) + '%' 
  }

console.log('team', team)
function crossIndicator(square_num:number){
  if (square_num && square_num > 0)
  {
      return "x ".repeat(square_num)
  }
  return ""
}

  return (
    <>
    <div className='team-page'>
    <div className="tabs-row">
      <p className='tab' onClick={() => dispatch(setScreenTab('voyage'))}>ПУТЬ</p>
        <p className='tab' onClick={() => dispatch(setScreenTab('inventory'))}>ЭКИП.</p>
        <p className='selected tab'>ГРУППА</p>
        <p className='tab' onClick={() => dispatch(setScreenTab('navigation'))}>КАРТА</p>
        
          
    </div>

    <div className='team-overview'>
      <div className='tabs-row'>
      <p className={ !showRoomTab? 'tab selected': 'tab'} onClick={showRoomTab? () => setShowRoomTab(0): () => {} }>Показатели</p>
      <p className={ showRoomTab? 'tab selected': 'tab'} onClick={!showRoomTab? () => setShowRoomTab(1): () => {} }>Экипажи</p>
      </div>
      {
        !showRoomTab ?
        <div className='breakable-grid'>
          <div className='non-breaking-half'>
              <div className='line'>

                <div className='flex-block hypderdive-circular-map'>
                    <div className='layer-5 rotation-transform'></div>
                    <div className='layer-4 rotation-transform '></div>
                    <div className='layer-3 rotation-transform '></div>
                    <div className='layer-2 rotation-transform'></div>
                    <div className='layer-1 rotation-transform'></div>

                    <div className='map-grid rotation-transform'> </div>

                    <div className='layer-0 rotation-transform'></div>

                    <div className='border-wave-top'></div>
                    <div className='border-wave-bottom'></div>
                    <div className={'player-icon-layer-' + (team?.dive_level) +' pulsate'}></div> 

                    <div  className='title-top'><small>{team?.dive_level} слой пространства</small></div>
                    <div  className='title-bottom'><small>{5 - team?.steps_after_dive} дн. до перехода</small></div>

                </div>
              </div>
              <div className='line'>
               <div className='flex-block'>
                <p>{team?.supply} снабжение</p>
                <p>{team?.order} дисциплина</p>
                <p>{team?.morale} мораль</p>

                </div>
                <div className='flex-block'>
                <p>{team?.faith} резонанс</p>
                <p>{team?.diplomacy} дипломатия</p>
                <p>{team?.aggression} агрессия</p>
                
                </div>
              </div>
          </div>
          <div className='non-breaking-half'>
              <div className='full-block'>

              <p>{team?.freight} / {team?.freight_limit} груз</p>
              <p className='inactive'>x биолаборатория</p>
              <p className='inactive'>x ремонтный модуль</p>
              <p className='inactive'>x горнодобывающий модуль</p>
              <p className='inactive'>x осадная артиллерия</p>

              </div>
          </div>


          <div className='non-breaking-half '>
              <div className='line'>

                <div className='non-flex-block'>
                      <h2>{team?.karma}</h2> 
                      <p>ОЧКИ</p>
                      <p>КАРМЫ</p>
                </div>

                <div className='flex-block'>
                <p>
                  <p>Вероятность встречи c караванами: {karmaToProbability(team?.karma)}</p>
                </p>
                  <div className='btn display-none'  onClick={()=>{setKarmaModal(1)}}>ОБРЯД ОХОТЫ</div>
                </div>
            </div>
          </div>
          <div className='non-breaking-half'>
          <div className='line display-none'>
              <div className='full-block'>
              <p>
                  <span className=''>{userLocations?.location?.name}</span>
                  <span>{" 🡢 "}</span>
                  <span className=''>{userLocations?.destination?.name}</span>
              </p>
              {/*<div className='btn ' onClick={() => navigate('/stations_graph' + `/?hash_id=${hash_id}`)}>ВЫБОР МАРШРУТА</div>
              <div className='btn' onClick={() => dispatch(setScreenTab('navigation'))}>ВЫБОР МАРШРУТА</div>*/}

              </div>
          </div>
          </div>
        </div>
        :
        <div className='team-showroom'>
          {
            team?.users.map((u, index)=>{
                let hull = u?.equips?.find((el:any) => el.item_type === "hull")
                
                return(<div className={index % 2 == 0?
                  'ship-card' : 'ship-card'}>
                <div className='indicators'>
                  <div className='indicators-name'> 
                  <p>КОР</p>
                  <p>ЭНР</p>
                  <p>СПЦ</p>
                  <p>ИИ</p>
                  </div>
                  <div className='indicators-count'>
                  <p>{u?.hp}/{u?.total_stats?.hp}</p>
                  <p>{u?.ep}/{u?.total_stats?.ep}</p>
                  <p>{u?.sp}/{u?.total_stats?.sp}</p>
                  <p>{u?.ai}/{u?.total_stats?.ai}</p>

                  </div>
                </div>
                <div className='circle'> </div>
                <img className='ship-img' src={hull?.img}></img>

            </div>)
              })
          }

          </div>
        }
    </div>
    </div>
        

    <div className='hud no-opacity'>


        
        <div className="commands-panel">

          
          <div className="command-order-btns-row">
            <div className="order-btn inactive">ПЕРЕДИТЕ В УПРАВЛЕНИЕ</div>
          </div>
        </div>

        <div className={karmaModal ? 'bottom-drawer': 'bottom-drawer hidden'}>  
          <div className='drawer-header'>Обряд</div>
            <p> Вы можете провести темный обряд понижения кармы. Жрецы Кхарза верят, что при низкой карме гиперпространство неминуемо столкнет вас с другими караванами.</p>
            <div className="command-order-btns-row">

            <div className="next-target-btn inactive" onClick={()=>{setKarmaModal(0)}}>НАЗАД</div>
            <div className="order-btn" onClick={()=>{decreaseKarma();setKarmaModal(0)}}>ПРОВЕСТИ ОБРЯД</div>

          </div>
        </div>
      </div>
    </>
    //      <axesHelper />

  )
}
