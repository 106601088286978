import * as React from "react";
import { render } from "react-dom";
import '../../pages/App.css'
import {setBattle} from '../../slices/battleSlice';
import { RootState } from "../../store/store";
import ky from 'ky';

import { useSelector, useDispatch } from "react-redux";


export default function Counter() {
    const dispatch = useDispatch();
    const battle = useSelector((state:RootState)=> state.battle);
    const [counter, setCounter] = React.useState(60);

    const new_time = Math.floor(((new Date(battle.step_end_date)).getTime() -(new Date()).getTime() )/100)/10



    const urlParams = new URLSearchParams(window.location.search);
    const hash_id = urlParams.get('hash_id');
  // Suggested by Laurent
  //console.log('tick')
  const API_BASE_URL = process.env.REACT_APP_BASE_URL;

  async function fetchBattle(battle_id: number) {
    try {
      // Extract the hash_id from the URL query parameters
      // Add hash_id to the fetch request URL
      //const requestUrl = `${API_BASE_URL}/battle?hash_id=${hash_id}`;
      
      const requestUrl = `${API_BASE_URL}/gameplay/fights/get_fight/?fight_id=${battle_id}&hash_id=${hash_id}`;

      const result: any= await ky.get(requestUrl, {credentials: 'include' }).json();
      if (result['step_num'] != battle.step_num || (result['step_num'] == 0 && result['status'] == 'archived' )){
        console.log('get_fight', {'result': result})
        dispatch(setBattle(result));
      }
    } catch (error) {
      console.error('Could not fetch battle:', error);
    }
  };

  React.useEffect(() => {


      if(counter && counter < 0){
        if(battle.status == 'active' || battle.status == 'waiting' )
        {
          fetchBattle(battle.id) //??????????????????? // это будет закидывать запрос на перезагрузку бд каждый раз, когда истекает таймер
          setTimeout(() => setCounter(/*counter - 1*/new_time), 300);
        }
      }else{
          setTimeout(() => setCounter(/*counter - 1*/new_time), 100);
      }

  }, [battle, counter]);



  return (
    <>
      { battle.status == 'active' || battle.status == 'waiting' ?
      <p className={counter < 5.1? 'counter red-highlight':'counter'}>
          {counter.toFixed(1)} СЕК
      </p>
      :
      <p>
      ...
      </p>
      }
    </>
  );
}