import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: number = 0

const sound = createSlice({
  name: 'sound',
  initialState,
  reducers: {
    setSound: (state, action: PayloadAction<number>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setSound} = sound.actions
export default sound.reducer
