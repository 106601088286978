import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';

const initialState: number = 0;

export const showShipCardIdSlice = createSlice({
  name: 'showShipCardId',
  initialState,
  reducers: {
      setShowShipCardId: (state, action: PayloadAction<number>) => {
        return action.payload;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});


export const { setShowShipCardId } = showShipCardIdSlice.actions;

export default showShipCardIdSlice.reducer;
