import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { UserLocations } from '../models/types';

const initialState: UserLocations = {
  location: null,
  destination: null,
  home_station: null,
  location_history: []
};

export const userLocationsSlice = createSlice({
  name: 'UserLocations',
  initialState,
  reducers: {
      setUserLocations: (state, action) => {
        state.location = action.payload?.location;
        state.destination = action.payload?.destination;
        state.home_station = action.payload?.home_station;
        state.location_history = action.payload?.location_history;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setUserLocations } = userLocationsSlice.actions;

export default userLocationsSlice.reducer;
