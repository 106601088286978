import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { PosArray } from '../models/types';

const initialState: PosArray = {
  step_num: 0,
  pos_array:[]
};

export const posArraySlice = createSlice({
  name: 'posArray',
  initialState,
  reducers: {
      setPosArray: (state, action) => {
        state.pos_array = action.payload?.pos_array;
        state.step_num = action.payload?.step_num;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setPosArray } = posArraySlice.actions;

export default posArraySlice.reducer;
