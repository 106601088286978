import React,{useRef, useEffect}from 'react';

import {Canvas, useFrame, useThree} from '@react-three/fiber';
import * as THREE from "three";
import '../../pages/App.css'
import { RootState } from "../../store/store";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Pos } from '../../models/types'; // Import the PosArray type definition
import { BackSide } from 'three';
import { useSelector} from "react-redux";
import { Html, Text } from '@react-three/drei';

const loader = new TextureLoader();

const texture_rocket_block  = loader.load("../markers/whitecross.svg");
const animation_speed_coef = 5/100000

interface IndicatorsProps {
  posArrayAlly: Pos[];
  posArrayEnemy: Pos[];
  battle: any; // Replace 'Battle' with the actual type of your 'battle' object
  onClickFunc: any;
}


export default function Indicators({ posArrayAlly, posArrayEnemy, battle, onClickFunc}: IndicatorsProps) {


interface HtmlProps {
  id: any;
  indicator_label: string; // Define the expected prop
  last_command: string;
  onClick: () => void; // Function type for click handler
}


function getCoords(alpha:number, beta:number, radius:number){
  return {x : Math.cos(alpha) * Math.cos(beta) * radius,
    y: Math.sin(beta) * radius, 
    z: Math.sin(alpha) * Math.cos(beta) * radius
  }
}

function commandTranslate(command_name: string){

  if(command_name == "trade"){return "ТОРГ"}
  if(command_name == "fight"){return "БОЙ"}
  if(command_name == "evade"){return "УЙТИ"}

  if(command_name == "def"){return "ЩИТ"}
  if(command_name == "atk"){return "АТК"}
  if(command_name == "over"){return "ПУСК"}

  if(command_name == "squadron"){return "ИСТ"}
  if(command_name == "ew_pulse"){return "РЭБ"}
  if(command_name == "emi_over"){return "ЭМИ"}
  if(command_name == "traps"){return "ЛОВ"}
  return command_name
}

  
interface IndicatorsGeneralProps {
  posArray: any;
  OnClick: any; // Define the expected prop
}

  const IndicatorsGeneral: React.FC<IndicatorsGeneralProps> = ({ posArray,  OnClick}) => {
    const markRefs :any = useRef([]);
    let last_command = null;
    // you can access the elements with itemsRef.current[n]
    let elapsedTime = 0
    let alpha = 0
    let beta = 0 
    let d = 0
    let sc = 0
    let new_pos = {x:0, y: 0, z:0}
    let j = 0
    let time_shift = 0
    const particlesCnt = posArray.length
    useEffect(() => {
      markRefs.current = markRefs.current.slice(0, particlesCnt);
      console.log()
    }, [posArray]);


    //const markRefs :any = useRefArray(posArrayEnemy.length);
    useFrame(() => {
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;
      //disposeGroup(groupRef.current)


      for (j = 0; j < particlesCnt; j++) {

        
        //position change
            
        alpha = posArray[j].alpha + time_shift * posArray[j].alpha_delta / 2;
        beta = posArray[j].beta + time_shift * posArray[j].beta_delta / 2;

        new_pos = getCoords(alpha, beta, posArray[j].radius)

        if (markRefs.current[j]) {  
          markRefs.current[j].position.x = new_pos.x;
          markRefs.current[j].position.y = new_pos.y;
          markRefs.current[j].position.z = new_pos.z;
        }
      }

    });

    return (
      <>
        {posArray.map((pos:any, index:any)=>{
        last_command = battle.battle_logs.filter((el:any) => el.user_id == pos?.id && el.step_num == battle.step_num - 1)[0]

        return(
          <group 
            ref={(sprite) => (markRefs.current[index] = sprite)}  // Use the ref from the array
            position={[pos.x, pos.y, pos.z]}
            key={"mark-enemy"+pos.x +"-"+pos.y}            

          >
            <Html
              zIndexRange={[10, 0]} 
              key = {'html-memorized-indicator'+ pos?.id}
              renderOrder={1}
              >
                <div
                  className="radar-indicator noselect pulsate-once"
                  onClick={() => OnClick(pos?.id)}
                >
                  <small className="row highlight">{`${posArray[index]?.hp} / ${posArray[index]?.ep}`}</small>
                  <small className="row">{last_command && last_command.command ? commandTranslate(last_command.command) : '--'}</small> {/* This could be replaced with the desired data */}
                </div>
            </Html>
            
            </group>)

        }
      )}

      </>

    );
  }

  function AllyTargetClick (obj:any){
    return onClickFunc(obj, 'ally')
  }
  function EnemyTargetClick (obj:any){
    return onClickFunc(obj, 'enemy')
  }

  const AllIndicators = () => {
    return (<>
    <IndicatorsGeneral posArray={posArrayAlly} OnClick={AllyTargetClick}/>
    <IndicatorsGeneral posArray={posArrayEnemy} OnClick={EnemyTargetClick}/>
    </>) 

}

  return (
    <>
      <AllIndicators/>
    </>
  );
}
