import React, { useRef, useState } from 'react';
/*
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";*/
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line } from "@react-three/drei";

/*#FEDBB7
#CBAF92
#A28C75
#82705E
#685A4B
#53483C
#423A30
#352E26
#2A251E
#221E18
Black
 */
import './App.css'
// import styles from "./About.module.css"
import { DoubleSide } from 'three'



export default function AboutPage2() {
  return (
    <>
    
    <div className='inventory'>

    {/*<div className='category'>
      <div className='card-row'>

        <div className='terminal'>
          <div className='window'>
                <img src="\planet_graph.jpg"/>
                <div className='description'>
          <p>hp ■ ■ ■ ■</p>
            <p>ep ■ ■ ■ ■</p>
            <p>sp ■ ■</p>
            <p>ai ■ ■ ■ ■</p>
            <p>morale ■ ■</p>
            <p>food ■ ■</p>
            <p>nav ■ ■</p>
            <p>cargo ■ ■</p>
          </div>
          </div>
            <div className='keyboard'>
            <img src="\planet_graph.jpg"/>

            </div>
          </div>
        </div>
  </div>*/}

      
      <div className='category'>
      <div className='card-row'>
        
        <div className='ship-card'>
          <div className='circle'/>
          <img src="\shiptrans3.png"/>
          <div className='middle-line'/>
          <div className='title'>
              <h3>Swordfish mk.III</h3>
            </div>
          <div className='description-right'>
          <p>hp ■ ■ ■ </p>
            <p>ep ■ ■ ■</p>
            <p>sp ■ ■</p>
            <p>ai ■ ■ ■ ■</p>
          </div>
          <div className='description-left'>

              <p className='highlight'>dreadnought</p>
            <p>■ ■ ■ equip</p>
            <p>■ ■ crew</p>
            <p>■ ■ cargo</p>
          </div>

        </div>




      </div>
        
      </div>

      <div className='category'>
      <h2 className='title'>Crew 2/2</h2>

      <div className='card-row'>


            <div className='crew-card'>
              <img src="./crew1.jpg"/>
            <div className='name highlight'>
            <p><b>Robert Paulson</b></p>

            <small>Engineer</small>
            </div>

              <div className='middle-line'></div>
              <div className='description-right'>
                <p>-1 morale</p>
                  <p>-1 ai delay</p>
                  <small>Filter out extension requests, override fetch and XHR requests, mock with overrides faster with better UI, and more.</small>
              </div>
            </div>

            <div className='crew-card'>
            <img src="./crew1.jpg"/>
            <div className='name highlight'>
            <p><b>Robert Paulson</b></p>

            <small>Engineer</small>
            </div>

              <div className='middle-line'></div>
              <div className='description-right'>
                <p>-1 morale</p>
                  <p>-1 ai delay</p>
                  <small>Filter out extension requests, override fetch and XHR requests, mock with overrides faster with better UI, and more.</small>
              </div>
            </div>
      </div>

      </div>

      <div className='category'>
        <h2 className='title'>Equipment 3/4</h2>

        <div className='card-row'>
          <div className='equip-card'>
              <div className='circle'/>
              <img src="\item1.png"/>
              <div className='title'>
                <b>Hydroponic System HB1</b>
              </div>
              <div className='stats'>+ 1 food</div>
              <div className='description'>
                <small>Filter out extension requests, override fetch and XHR requests.</small>
              </div>
            </div>
            <div className='equip-card'>
            <div className='circle'/>

              <div className='circle'/>
              <img src="\item2.png"/>
              <div className='title'>
                <b>Quantum Generator</b>
              </div>
              <div className='stats'>+1 ep</div>

              <div className='description'>
              <small>See the changes in fetch priority for network events and improve performance.</small>
              </div>
            </div>

            <div className='equip-card'>
            <div className='circle'/>

              <div className='circle'/>
              <img src="\item3.png"/>
              <div className='title'>
                <b>Interceptor XR-51</b>
              </div>
              <div className='stats'>Airstrike attack</div>

              <div className='description'>
              <small>The position data type is specified with one or two keywords, with optional offsets.</small>
              </div>
            </div>

            <div className='equip-card-placeholder'>
                <h1>+</h1>
            </div>
        </div>

      </div>


      <div className='category'>
        <h2 className='title'>Inventory</h2>

        <div className='card-row'  style={{opacity:0.5}}>

        <div className='ship-card'>
          <div className='circle'/>
          <img src="\shiptrans2.png"/>
          <div className='middle-line'/>
          <div className='title'>
              <h3>Cocojumbo 3</h3>
            </div>
          <div className='description-right'>
          <p>hp ■ ■ ■ </p>
            <p>ep ■ ■ ■</p>
            <p>sp ■ ■</p>
            <p>ai ■ ■ ■ ■</p>
          </div>
          <div className='description-left'>

              <p className='highlight'>dreadnought</p>
            <p>■ ■ ■ equip</p>
            <p>■ ■ crew</p>
            <p>■ ■ cargo</p>
          </div>

        </div>

        <div className='ship-card'>
          <div className='circle'/>
          <img src="\shiptrans2.png"/>
          <div className='middle-line'/>
          <div className='title'>
              <h3>Cocojumbo 3</h3>
            </div>
          <div className='description-right'>
          <p>hp ■ ■ ■ </p>
            <p>ep ■ ■ ■</p>
            <p>sp ■ ■</p>
            <p>ai ■ ■ ■ ■</p>
          </div>
          <div className='description-left'>

              <p className='highlight'>dreadnought</p>
            <p>■ ■ ■ equip</p>
            <p>■ ■ crew</p>
            <p>■ ■ cargo</p>
          </div>

        </div>

        <div className='ship-card'>
          <div className='circle'/>
          <img src="\shiptrans1.png"/>
          <div className='middle-line'/>
          <div className='title'>
              <h3>Cocojumbo 3</h3>
            </div>
          <div className='description-right'>
          <p>hp ■ ■ ■ </p>
            <p>ep ■ ■ ■</p>
            <p>sp ■ ■</p>
            <p>ai ■ ■ ■ ■</p>
          </div>
          <div className='description-left'>

              <p className='highlight'>dreadnought</p>
            <p>■ ■ ■ equip</p>
            <p>■ ■ crew</p>
            <p>■ ■ cargo</p>
          </div>

        </div>


        </div>

      </div>
    </div>

    <div className='hud'>
    {/*<div className='info-panel-right'>
        <div className='text-stats-right'>
        <p>■ ■ ■ ■ hp</p>
            <p>■ ■ ■ ■ ep</p>
            <p>■ ■ sp</p>
            <p>■ ■ ■ ■ ai</p>
        </div>
        
      </div>
      <div className='info-panel-left'>
        <div className='text-stats-left'>
        <p>morale ■ ■</p>
            <p>food ■ ■</p>
            <p>nav ■ ■</p>
            <p>cargo ■ ■</p>
        </div>
        
      </div>

      <div className='info-panel-center'>
        total        
      </div>*/}
    <div className='order-btn'>
      <a href="/fight"><h2>READY</h2></a>
    </div>
    </div>
    </>
    //      <axesHelper />

  )
}
