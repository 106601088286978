import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { voyageHistory } from '../models/types';

const initialState: voyageHistory = {
  events:[]
};

export const voyageHistorySlice = createSlice({
  name: 'voyageHistory',
  initialState,
  reducers: {
    setVoyageHistory: (state, action) => {
        state.events = action.payload?.events;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setVoyageHistory } = voyageHistorySlice.actions;

export default voyageHistorySlice.reducer;
