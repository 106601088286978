import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';

const initialState: string = 'voyage';

export const screenTabSlice = createSlice({
  name: 'screenTab',
  initialState,
  reducers: {
      setScreenTab: (state, action: PayloadAction<string>) => {
        return action.payload;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});


export const { setScreenTab } = screenTabSlice.actions;

export default screenTabSlice.reducer;
