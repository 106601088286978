import React, { useRef, useState } from 'react';
/*
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";*/
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line } from "@react-three/drei";

/*#FEDBB7
#CBAF92
#A28C75
#82705E
#685A4B
#53483C
#423A30
#352E26
#2A251E
#221E18
Black
 */
import './App.css'
// import styles from "./About.module.css"
import { DoubleSide } from 'three'


const sizes={
  width: window.innerWidth,
  height: window.innerHeight
}

const starRadius = 1
const station_alpha = Math.random()*2 * Math.PI
const planet_alpha = Math.random() * 2 * Math.PI
const particlesCntAlly = 3
const angArrayAlly = new Array(particlesCntAlly)
const posArrayAlly = new Array(particlesCntAlly)
const radiusAlly = 0.7 + (Math.random()/2 -  1/4)*0

const planetRadius = 0.3
const orbitMaxRadius = 20
const starOrbitShift = 15
const starOrbitCoef = 0.5


const stationOrbitRadius = 1.5
const stationOrbitShiftZ = 0.7
const stationOrbitCoef = 1.7

const lunarRadius = 0.1
const stationLunarOrbitDelta = 0.7
const lunarOrbitCoef = 2
const stationLunarOrbitInclineADelta = 0//Math.random()/4 
const stationLunarOrbitInclineBDelta = 0//Math.random()/4 
const lunar_alpha = Math.random() * 2 * Math.PI

const stationOrbitInclineA = Math.random()/4 + 0.1
const stationOrbitInclineB = Math.random()/4 + 0.1

angArrayAlly[0] = {alpha: 0, beta: 0}
  posArrayAlly[0] = {
    x: 0, 
    y: 0, 
    z: 0}

for (let i = 1; i < particlesCntAlly; i++){
  let alpha = Math.random() * 2 *Math.PI
  let beta = Math.random() * 2  * Math.PI
  angArrayAlly[i] = {alpha: alpha, beta: beta}
  posArrayAlly[i] = {
    x: Math.cos(alpha)* Math.cos(beta) * radiusAlly, 
    y: Math.sin(beta) * radiusAlly, 
    z: Math.sin(alpha) * Math.cos(beta) * radiusAlly}
}

const particlesCntEnemy = 3
const posArrayEnemy = new Array(particlesCntEnemy)
const angArrayEnemy = new Array(particlesCntEnemy)
const radiusEnemy = 2


for (let i = 0; i < particlesCntEnemy; i++){
  //let alpha2 = Math.random() * 2 *Math.PI
  //let beta2 = Math.random() * 2  * Math.PI
  let alpha2 = 0
  let beta2 = 0
  if(i==0){
    alpha2 = (Math.random() -0.5) *Math.PI/4
    beta2 = (Math.random() -0.5) * Math.PI/4
  }
  else
  {
    alpha2 = angArrayEnemy[i-1].alpha + (Math.random() +0.2) *Math.PI / 3
    beta2 = angArrayEnemy[i-1].beta + (Math.random() -0.5)  * Math.PI /3
  }
  angArrayEnemy[i] = {alpha: alpha2, beta: beta2}
  posArrayEnemy[i] = {
    x: Math.cos(alpha2)* Math.cos(beta2) * radiusEnemy, 
    
    y: Math.sin(beta2) * radiusEnemy,
     
    z: Math.sin(alpha2) * Math.cos(beta2) * radiusEnemy}
};

const Star = () =>{
  
      let a = orbitMaxRadius + starOrbitShift
      let b = orbitMaxRadius*starOrbitCoef
      let x = a *Math.cos(planet_alpha)
      let z =  b*Math.sin(planet_alpha)
  return(
    <>

    <mesh position={[x, 0, z]}>
      <sphereGeometry args ={[starRadius, 100, 50]}/>
      <meshBasicMaterial color="#FEDBB7" />
    </mesh>
    </>

  );
};



const Planet = () =>{
  
  return(
    <>
    {/*<mesh>
      <sphereGeometry args ={[planetRadius + 0.05, 20, 10]}/>
      <meshBasicMaterial wireframe={true} color="#CBAF92" />
  </mesh>*/}
<mesh>
      <sphereGeometry args ={[planetRadius, 100, 50]}/>
      {/*<meshBasicMaterial color="grey" />*/}
            <meshBasicMaterial color="#A28C75" />
    </mesh>
    </>

  );
};

const Sphere2 = () =>{
  return(
    <points>
      <sphereGeometry args ={[2, 30, 15 ]}/>
    <pointsMaterial size={0.01} sizeAttenuation color={"#A28C75"}/*color={"#53483C"}*//>
    </points>
  );
};

const RandomSphere =() =>{
  const particlesCnt = 500
  const posArray = new Float32Array(particlesCnt*3)
  const radius = 20

  for (let i = 0; i < particlesCnt*3; i+=3){
    let alpha = Math.random() * 2 *Math.PI 
    let beta = Math.random() * 2  * Math.PI
    posArray[i] = Math.sin(alpha)* Math.cos(beta) * radius
    posArray[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
    posArray[i+2] = Math.sin(beta) * radius
  }

  return(
    <points>
      <bufferGeometry>
      <bufferAttribute
               // ref={positionsRef}
                attach='attributes-position'
                array={posArray}
                count={posArray.length / 3}
                itemSize={3}
            />
      </bufferGeometry>
      <pointsMaterial size={0.1} color={"grey"}/>
    </points>
  );
};

const Mark = () =>{

  const loader = new TextureLoader();
  // A png with transparency to use as the target sprite.
  const texture = loader.load("triangle1.svg");
  let radius = 1

  let alpha = Math.random() * 2 *Math.PI 
  let beta = Math.random() * 2  * Math.PI
  let x= Math.sin(alpha)* Math.cos(beta) * radius
  let y = Math.cos(alpha) * Math.cos(beta) * radius
  let z= Math.sin(beta) * radius

  return (
    <sprite
    position={[x, y, z]}
    scale={0.3}
    >
      
    <spriteMaterial map={texture} 
         
          rotation={Math.PI}
    />
  </sprite>
  );
}


const MarksAlly=() =>{

  const loader = new TextureLoader();
  const texture = loader.load("triangle1.svg");
  const player_texture = loader.load("triangle_fill.svg")
  return (
    <>
    {posArrayAlly.map((pos)=>{
      return(
      <sprite
        position={[pos.x, pos.y, pos.z]}
        scale={0.3}
        >
          
        <spriteMaterial map={pos.x == 0 && pos.y == 0 && pos.z == 0 ? player_texture : texture } 
            
              rotation={Math.PI}
        />
        </sprite>)
      })
    }
    </>

  );

};


const MarksEnemy=() =>{

  const loader = new TextureLoader();
  const texture = loader.load("triangle2.svg");
  return (
    <>
    {posArrayEnemy.map((pos)=>{
      return(
      <sprite
        position={[pos.x, pos.y, pos.z]}
        scale={0.3}
        >
          
        <spriteMaterial map={texture} 
            
              rotation={Math.PI}
        />
        </sprite>)
      })
    }
    </>

  );

};



const TraceTorus = () => {
  //useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01));

  return (
    <>
         <mesh
        visible
        position={[0,0,0]}
        rotation={[-Math.PI/2 + angArrayEnemy[0].alpha, -angArrayEnemy[0].alpha,  -Math.PI/2 + angArrayEnemy[0].beta]}
        >
           <torusGeometry args={[2, 0.005, 6, 150, Math.PI]} />
           <meshBasicMaterial attach="material" color="#CBAF92" />
     </mesh>
     <mesh
        visible
        position={[0,0,0]}
        rotation={[0, -angArrayEnemy[0].alpha,0]}
        >
           <torusGeometry args={[2, 0.005, 4, 150, 6 ]} />
           <meshBasicMaterial attach="material" color="#CBAF92" />
     </mesh>

     <mesh
     visible
        position={[0,0,0]}
        rotation={[10, 0, 1]}

        >
           <torusGeometry args={[2, 0.005, 6 , 150, 10]} />
           <meshBasicMaterial attach="material" color="#CBAF92" />
     </mesh>

     </>
  );
}




const OrbitStar = () =>{
  // Create a curve based on the points
  let result_trace = Array(particlesCntAlly - 1)
  let result_path = Array(particlesCntAlly - 1)

      let a = orbitMaxRadius
      let b = orbitMaxRadius*starOrbitCoef
      let n = 50
      let slice_n = 20
      let slice_phi = 0.5
      let alpha = 0
      let shift_x = a*Math.cos(planet_alpha)
      let shift_z =  b*Math.sin(planet_alpha )
      
      let points = Array(n)
      let points_trace = Array(slice_n)

      for (let i = 0; i < n; i++){  
        alpha =  planet_alpha - Math.PI + 2*Math.PI *(i/(n-1)) 
        points[i] = new THREE.Vector3(a*Math.cos(alpha) +shift_x,0, b*Math.sin(alpha) + shift_z)
      }
      let curve = new THREE.CatmullRomCurve3(points)
      for (let i = 0; i < slice_n; i++){  
        alpha =  planet_alpha - Math.PI + slice_phi * i/(slice_n-1)
        points_trace[i] = new THREE.Vector3(a*Math.cos(alpha) +shift_x,0, b*Math.sin(alpha) + shift_z)
      }
      let curve_trace = new THREE.CatmullRomCurve3(points_trace)
  return (
    <>          
            <mesh>
            <tubeGeometry args={[curve_trace, slice_n, 0.01, 4, false]} />
            <meshBasicMaterial attach="material" color={"#A28C75"} />
          </mesh>

          <points>
          <tubeGeometry args={[curve, n*5, 0.005, 4, true]} />
          <pointsMaterial size={0.02} color={"#A28C75"}/>
          </points>
          
      
    </>
  )
}


const OrbitLunar = () =>{
  // Create a curve based on the points
  let result_trace = Array(particlesCntAlly - 1)
  let result_path = Array(particlesCntAlly - 1)

      // Create an empty array to stores the points
      let points = []
      // Define points along Z axis

      let a = stationOrbitRadius + stationLunarOrbitDelta
      let b = a*lunarOrbitCoef
      let n = 80
      let slice_n = 8
      let alpha = 0
      let shift_x = 0
      let shift_z = stationOrbitShiftZ
      points = Array(n)

      for (let i = 0; i < n; i++){  
        alpha =  2*Math.PI *(i/n) + lunar_alpha
        let x = (a*Math.cos(alpha) +shift_x)
        let z = (b*Math.sin(alpha) + shift_z)
        let y = - (stationOrbitInclineA+stationLunarOrbitInclineADelta)  * x - (stationOrbitInclineB+stationLunarOrbitInclineBDelta)*  z
        points[i] = new THREE.Vector3(x,y,z)
      }
      let curve2 = new THREE.CatmullRomCurve3(points)
      let curve1 = new THREE.CatmullRomCurve3(points.slice(0,slice_n))
  return (
    <>          
            <mesh>
            <tubeGeometry args={[curve1, slice_n, 0.01, 4, false]} />
            <meshBasicMaterial attach="material" color={"#A28C75"} />
          </mesh>
          


          <points>
          <tubeGeometry args={[curve2, n, 0.005, 4, false]} />
          <pointsMaterial size={0.01} color={"#A28C75"}/>
          </points>
          
      
    </>
  )
}


const Lunar = () =>{
  let a = stationOrbitRadius + stationLunarOrbitDelta
  let b = a*lunarOrbitCoef
  
  let shift_x = 0
  let shift_z = stationOrbitShiftZ
    let x = (a*Math.cos(lunar_alpha) +shift_x)
    let z = (b*Math.sin(lunar_alpha) + shift_z)
    let y = - (stationOrbitInclineA+stationLunarOrbitInclineADelta)  * x - (stationOrbitInclineB+stationLunarOrbitInclineBDelta)*  z
    return(
<>

<mesh position={[x, y, z]}>
  <sphereGeometry args ={[lunarRadius, 100, 50]}/>
  <meshBasicMaterial color="#A28C75" />
</mesh>
</>

);
};


const OrbitStation = () =>{
  // Create a curve based on the points
  let result_trace = Array(particlesCntAlly - 1)
  let result_path = Array(particlesCntAlly - 1)

      // Create an empty array to stores the points
      let points = []
      // Define points along Z axis

      let a = stationOrbitRadius
      let b = a*stationOrbitCoef
      let n = 80
      let slice_n = 8
      let alpha = 0
      let shift_x = 0
      let shift_z = stationOrbitShiftZ
      points = Array(n)

      for (let i = 0; i < n; i++){  
        alpha =  2*Math.PI *(i/n) + station_alpha
        let x = (a*Math.cos(alpha) +shift_x)
        let z = (b*Math.sin(alpha) + shift_z)
        let y = - stationOrbitInclineA  * x - stationOrbitInclineB*  z
        points[i] = new THREE.Vector3(x,y,z)
      }
      let curve2 = new THREE.CatmullRomCurve3(points)
      let curve1 = new THREE.CatmullRomCurve3(points.slice(0,slice_n))
  return (
    <>          
            <mesh>
            <tubeGeometry args={[curve1, slice_n, 0.01, 4, false]} />
            <meshBasicMaterial attach="material" color={"#A28C75"} />
          </mesh>
          


          <points>
          <tubeGeometry args={[curve2, n, 0.005, 4, false]} />
          <pointsMaterial size={0.01} color={"#A28C75"}/>
          </points>
          
      
    </>
  )
}

const Station = () =>{
      let a = stationOrbitRadius 
      let b = a*stationOrbitCoef
      
      let shift_x = 0
      let shift_z = stationOrbitShiftZ
        let x = (a*Math.cos(station_alpha) +shift_x)
        let z = (b*Math.sin(station_alpha) + shift_z)
        let y = - stationOrbitInclineA  * x - stationOrbitInclineB*  z
  return(
    <>
      <mesh position={[x,y,z]}>
        <sphereGeometry args ={[0.15, 4, 2 ]}/>
        <meshBasicMaterial wireframe={true} color={"cyan"} wireframeLinewidth={2} />
      </mesh>
          <mesh position={[x,y,z]}>
        <sphereGeometry args ={[0.1, 4, 2 ]}/>
        <meshBasicMaterial color={"cyan"} wireframeLinewidth={2} />
      </mesh>
    </>
  );
};




export default function AboutPage2() {
  return (
    <>

    <Canvas>
      <perspectiveCamera
      fov={75}
      aspect={sizes.width/sizes.height}
      position={[0,0,0]}
      near={0.1}
      far={100}
    >
      <Star/>
      <Planet/>
      <OrbitStation/>
      <OrbitStar/>
      <Station/>
      <RandomSphere/>

      <OrbitLunar/>
      <Lunar/>

      <OrbitControls minZoom={1} maxZoom={1} minDistance={2} maxDistance={20} enablePan={false}/>

      </perspectiveCamera>
    </Canvas>


    <div className='hud'>
    <div className='commands-panel'>
      <p className='segment1'>- attack <small>-1ep</small></p>
      <p className='segment2'>- defend <small>+1ep</small></p>
      <p className='segment3'>- overpowered <small>-2ep</small></p>        
      <p className='segment4'>+ special <small>{"->"}</small></p>            
    </div>

    <div className='order-btn'>
      <h2>ORDER</h2>
    </div>

    <div className='info-panel-left'>
      <div className='faction-logo'>

      </div>
      <p> A1 {"[player]"}</p>
      <small>Some info</small>
      <small>hyperion III</small>
      <div className='text-stats'> 
      <p>hp ■ ■ ■ </p>
      <p>ep ■ ■ x</p>
      <p>sp ■ x</p>
      <p>ai ■ x x x</p>

      </div>
      </div>
      </div>
    </>
    //      <axesHelper />

  )
}
