import React from 'react';
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import * as THREE from "three";
import '../../pages/App.css'
import { RootState } from "../../store/store";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Pos } from '../../models/types'; // Import the PosArray type definition
import { BackSide } from 'three';
import { useSelector} from "react-redux";
import { Html, Text } from '@react-three/drei';

const loader = new TextureLoader();

const texture_rocket_block  = loader.load("../markers/whitecross.svg");
const animation_speed_coef = 5/100000

interface CommandAnimationsProps {
  posArray: Pos[];
  battle: any; // Replace 'Battle' with the actual type of your 'battle' object
}

export default function CommandAnimations({ posArray, battle }: CommandAnimationsProps) {

  // формирует массивы для анимации
  //let last_battle_log :any= []// battle.battle_logs.filter((e)=> e.step_num == battle.step_num -1)
  let successfull_attack :any = []// last_battle_log.filter((e)=> e.status == "success" && (e.command == "atk" || e.command == "over"))
  let blocked_attack :any = []// last_battle_log.filter((e)=> e.status == "success" && (e.command == "atk" || e.command == "over"))
  let successfull_overpower :any= []//last_battle_log.filter((e)=> e.status != "success" && (e.command == "atk" || e.command == "over"))
  let successfull_squadron :any= []
  let blocked_overpower :any= []//last_battle_log.filter((e)=> e.status != "success" && (e.command == "atk" || e.command == "over"))

  let successfull_ew_pulse :any= []//last_battle_log.filter((e)=> e.status != "success" && (e.command == "atk" || e.command == "over"))
  let successfull_heat_traps :any= []//last_battle_log.filter((e)=> e.status != "success" && (e.command == "atk" || e.command == "over"))

  //console.log('ya obnovilsya', posArray)
  let e :any = null
  let from :any ={}
  let to :any ={}
  let i = 0
  if(!battle || !battle.battle_logs){
    return false
  }
  for(i = 0; i< battle.battle_logs.length; i++){
    e = battle.battle_logs[i]
    from ={}
    to = {}
    if(e.step_num == battle.step_num -1&& (e.command == "atk" || e.command == "over" || e.command == "emi_over" || e.command == "squadron")){
      
      from = posArray.find((el:any) => el.id == e.user_id)
      to = posArray.find((el:any) => el.id == e.target_id)

      if(e.status == "success") {
        if(e.command == "over" || e.command == "emi_over"){
          successfull_overpower.push({'from':from, 'to':to})
        }
        else if(e.command == "squadron"){
          successfull_squadron.push({'from':from, 'to':to})
        }else{
          successfull_attack.push({'from':from, 'to':to})
        }
      }else if(e.status == "counter") {
        successfull_attack.push({'from':to, 'to':from})
        blocked_attack.push({'from':from, 'to':to})
      }else{
        if(e.command == "over" || e.command == "emi_over" || e.command == "squadron"){
          blocked_overpower.push({'from':from, 'to':to})
        }
        else{
          blocked_attack.push({'from':from, 'to':to})
        }
      }
    }
    if (e.step_num == battle.step_num -1 && (e.command == "ew_pulse")){
      from = posArray.find((el:any) => el.id == e.user_id)
      to = posArray.find((el:any) => el.id == e.target_id)
      successfull_ew_pulse.push({'from':from, 'to':to})
    }
    if (e.step_num == battle.step_num -1 && (e.command == "traps")){
      from = posArray.find((el:any) => el.id == e.user_id)
      to = posArray.find((el:any) => el.id == e.target_id)
      successfull_heat_traps.push({'from':from, 'to':to})
    }
  }

  function RotatingShield(args:any) {
    const myMesh :any = React.useRef();

    let a = 0
    let delay = 1.5
    let end = 2.4

    useFrame(({ clock }) => {
      a = clock.getElapsedTime() - delay;
      if(args && args.pos)
      {
        myMesh.current.position.x = args.pos.x;
        myMesh.current.position.y = args.pos.y;
        myMesh.current.position.z = args.pos.z
        if(a>0 && a <= end){
          myMesh.current.rotation.y = a;
        }
        if(a <= end  && a>0){
          myMesh.current.visible = true
        }
        else{
          myMesh.current.visible = false
        }
      }
  
    });
  
    return (
      <mesh ref={myMesh}>
        <icosahedronGeometry args ={[0.2, 0]}/>
        <meshBasicMaterial wireframe={true} color={'white'} />
      </mesh>
    );
  }
  

  function getCoords(alpha:number, beta:number, radius:number){
    return {x : Math.cos(alpha) * Math.cos(beta) * radius,
      y: Math.sin(beta) * radius, 
      z: Math.sin(alpha) * Math.cos(beta) * radius
    }
  }

  //  function RocketAnimation(args:any) {
  interface AnimationProps {
    args: any;
  }
  
  const EwPulseAnimation: React.FC<AnimationProps> = ({ args }) => {
    const ewpulse :any = React.useRef();
    const ewpulse_indicator :any = React.useRef();

    const clock = React.useRef(new THREE.Clock());
    let end = 20
    let a = 0
    let from_alpha = 0
    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let dec_a = 0
    const delay = 0
    let elapsedTime = 0
    let time_shift = 0

    useFrame(() => {
      elapsedTime = clock.current.getElapsedTime();

      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from)
      {

        a = elapsedTime*3- delay;

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)

        if(a>0 && a <= end){
          ewpulse.current.position.x = from.x;
          ewpulse.current.position.y = from.y;
          ewpulse.current.position.z = from.z;

        }
        if (ewpulse_indicator.current){
          if(a <= end && a> 0){
            ewpulse_indicator.current.style.display = 'block';  
          }
          else{
            ewpulse_indicator.current.style.display = 'none';  
          }
        }

      }
  
    });


    return (
      <>
        <group 
          ref={ewpulse} // Use the ref from the array
          visible={false}
        >
          <Html
            zIndexRange={[10, 0]} 
            ref={ewpulse_indicator}
            visible={false}
            >
              <div
                className="radar-animation-indicator noselect"
              >
                <div className='ew-animation'/>
                <div className='ew-pulse-animation'/>
              </div>
          </Html>
        </group>
      </>
    );
  }

  const HeatTrapsAnimation: React.FC<AnimationProps> = ({ args }) => {
    const ewpulse :any = React.useRef();
    const ewpulse_indicator :any = React.useRef();

    const clock = React.useRef(new THREE.Clock());
    let end = 20
    let a = 0
    let from_alpha = 0
    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let dec_a = 0
    const delay = Math.random()
    let elapsedTime = 0
    let time_shift = 0
    let x_orig = 0
    let y_orig = 0
    let z_orig = 0
    let x_rand = 0
    let y_rand = 0
    let z_rand = 0
    let rand_count = -1
    let curved_a = 0
    let trap_lifetime = 0.4
    let disable = 0

    useFrame(() => {
      elapsedTime = clock.current.getElapsedTime();

      time_shift = Date.now()*animation_speed_coef;
      


      if(args && args.from)
      {


        a = elapsedTime*3- delay;

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)

        if (a< 0.1){
          x_orig = from.x
          y_orig = from.y
          z_orig = from.z
        }

        if (rand_count != a - a%trap_lifetime){
          x_rand = (Math.random() -0.5) / 3
          y_rand = (Math.random() -0.5) / 3
          z_rand = (Math.random() -0.5) / 3
          rand_count = a - a%trap_lifetime
          disable = 1- disable
        }

        if(a>0 && a <= end){
          curved_a = 1.5 //+ 0* Math.atan(a%trap_lifetime)
          ewpulse.current.position.x = x_orig + x_rand*curved_a;
          ewpulse.current.position.y = y_orig + y_rand*curved_a;
          ewpulse.current.position.z = z_orig + z_rand*curved_a;
        }
        if (ewpulse_indicator.current){
          if(a <= end && a> 0){
            ewpulse_indicator.current.style.display = 'block';  
          }
          else{
            ewpulse_indicator.current.style.display = 'none';  
          }
        }

      }
  
    });


    return (
      <>
        <group 
          ref={ewpulse} // Use the ref from the array
          visible={false}
        >
          <Html
            zIndexRange={[10, 0]} 
            ref={ewpulse_indicator}
            visible={false}
            >
              <div
                className="radar-animation-indicator noselect"
              >
                <div className={args.from.enemy ? 'heat-trap-animation red-marker' : 'heat-trap-animation'}>+</div>

                </div>
          </Html>
        </group>
      </>
    );
  }

  const AttackAnimation: React.FC<AnimationProps> = ({ args }) => {
    const myMesh :any = React.useRef();
    const explosion:any = React.useRef();
    const end = 5
    const explosion_duration = 0.7
    const shift = 0.02
    const clock = React.useRef(new THREE.Clock());
    let a = 0
    let from_alpha = 0
    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let dec_a = 0
    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    const delay = 0
    let elapsedTime = 0
    let time_shift = 0
    let x_rand = 0
    let y_rand = 0
    let z_rand = 0
    let rand_count = 0

    useFrame(() => {
      elapsedTime = clock.current.getElapsedTime();

      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {

        a = elapsedTime*3- delay;

        if (rand_count != a - a%1){
          x_rand = (Math.random() -0.5)/10
          y_rand = (Math.random() -0.5)/10
          z_rand = (Math.random() -0.5)/10
          rand_count = a - a%1
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)


        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to = getCoords(to_alpha, to_beta, args.to.radius)
        to.x += x_rand;
        to.y += y_rand;
        to.z += z_rand;
        
        dec_a = a% 1 * (1-shift)
        
        explosion.current.position.x = to.x *(1-shift) + from.x* shift;
        explosion.current.position.y = to.y*(1-shift) + from.y* shift;
        explosion.current.position.z = to.z*(1-shift) + from.z* shift;

        if(a>0 && a <= end){
          myMesh.current.position.x = from.x*(1-dec_a) + to.x* (dec_a);
          myMesh.current.position.y = from.y*(1-dec_a) + to.y* (dec_a);
          myMesh.current.position.z = from.z*(1-dec_a) + to.z* (dec_a);
        }
        if(a>=  1&& a<= end+explosion_duration){
          if ( dec_a < explosion_duration){
            //explosion.current.material.opacity =  1
            explosion.current.scale.x =  dec_a*1.5 + 0.5
            explosion.current.scale.y =  dec_a*1.5 + 0.5
            explosion.current.scale.z =  dec_a*1.5+ 0.5
            explosion.current.visible = true
          }
        }
        if(a>=end+explosion_duration){
          explosion.current.visible = false
        }
  
        if(a <= end && a> 0){
          myMesh.current.visible = true
        }
        else{
          myMesh.current.visible = false
        }
      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
      <mesh ref={myMesh} visible={false}>
          <sphereGeometry args ={[0.03, 4,0]}/>
          <meshBasicMaterial attach="material" color={'white'} />
      </mesh>
      <mesh ref={explosion} visible={false} position={[pos_x,pos_y,pos_z]}>
          <sphereGeometry args ={[0.08, 12,12]}/>
          <meshBasicMaterial attach="material" transparent={true} color={'white'} />
      </mesh>
      </>
    );
  }
  
/*
  const BlockedAttackAnimationOld: React.FC<AnimationProps> = ({ args }) => {
    const myMesh :any = React.useRef();
    const mark:any = React.useRef();
    const end = 5
    const explosion_duration = 0.7
    const shift = 0.1
    const clock = React.useRef(new THREE.Clock());
    let a = 0

    let from_alpha = 0
    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let dec_a = 0
    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    const delay = 0
    let elapsedTime = 0
    let time_shift = 0
    let x_rand = 0
    let y_rand = 0
    let z_rand = 0
    let rand_count = 0

    useFrame(() => {
      elapsedTime = clock.current.getElapsedTime();

      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {

        a = elapsedTime*3- delay;

        if (rand_count != a - a%1){
          x_rand = (Math.random() -0.5)/10
          y_rand = (Math.random() -0.5)/10
          z_rand = (Math.random() -0.5)/10
          rand_count = a - a%1
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)


        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to = getCoords(to_alpha, to_beta, args.to.radius)
        to.x += x_rand;
        to.y += y_rand;
        to.z += z_rand;
        
        dec_a = a% 1 * (1-shift)
        
        mark.current.position.x = to.x *(1-shift) + from.x* shift;
        mark.current.position.y = to.y*(1-shift) + from.y* shift;
        mark.current.position.z = to.z*(1-shift) + from.z* shift;

        if(a>0 && a <= end){
          myMesh.current.position.x = from.x*(1-dec_a) + to.x* (dec_a);
          myMesh.current.position.y = from.y*(1-dec_a) + to.y* (dec_a);
          myMesh.current.position.z = from.z*(1-dec_a) + to.z* (dec_a);
        }
        if(a>= 1 && a<= end+explosion_duration){
          //mark.current.material.opacity = end+explosion_duration - a

          mark.current.visible = true
        }
        if(a>=end+explosion_duration){
          mark.current.visible = false
        }
  
        if(a <= end && a> 0){
          myMesh.current.visible = true
        }
        else{
          myMesh.current.visible = false
        }
      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
      <mesh ref={myMesh} visible={false}>
          <sphereGeometry args ={[0.03, 4,0]}/>
          <meshBasicMaterial attach="material" color={'white'} />
      </mesh>
      <sprite ref={mark} 
            position={[pos_x,pos_y,pos_z]}
            scale={0.3} visible={false}
            >
          <spriteMaterial map={texture_rocket_block}/>
            </sprite>
      </>
    );
  }
*/
  const BlockedAttackAnimation: React.FC<AnimationProps> = ({ args }) => {
    const myMesh :any = React.useRef();
    const shield:any = React.useRef();
    const end = 8
    const explosion_duration = 0.7
    const shift = 0.1
    const clock = React.useRef(new THREE.Clock());
    let a = 0

    let from_alpha = 0
    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let dec_a = 0
    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    let to_main = {x:0, y: 0, z:0}
    const delay = 0
    let elapsedTime = 0
    let time_shift = 0
    let x_rand = 0
    let y_rand = 0
    let z_rand = 0
    let rand_count = 0
    let to_vector = new THREE.Vector3(0,0,0);     // Center of the sphere
    let from_vector = new THREE.Vector3(0,0,0); // Target point to point towards
    let direction_vector = new THREE.Vector3(0,0,0); 
    let zAxis = new THREE.Vector3(0, 1, 0); // Assuming polar axis is Z
    let quaternion = new THREE.Quaternion()
    useFrame(() => {
      elapsedTime = clock.current.getElapsedTime();

      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {

        a = elapsedTime*3- delay;

        if (rand_count != a - a%1){
          x_rand = (Math.random() -0.5)/10
          y_rand = (Math.random() -0.5)/10
          z_rand = (Math.random() -0.5)/10
          rand_count = a - a%1
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)


        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to_main = getCoords(to_alpha, to_beta, args.to.radius)
        to.x = to_main.x + x_rand;
        to.y = to_main.y + y_rand;
        to.z = to_main.z + z_rand;
        
        dec_a = a% 1 * (1-shift)
        
        shield.current.position.x = to.x
        shield.current.position.y = to.y
        shield.current.position.z = to.z
        to_vector = new THREE.Vector3(to.x, to.y, to.z);     // Center of the sphere
        from_vector = new THREE.Vector3(from.x, from.y, from.z); // Target point to point towards

        // Calculate direction from 'to' to 'from'
        direction_vector = new THREE.Vector3().subVectors(from_vector, to_vector).normalize();
        quaternion = new THREE.Quaternion().setFromUnitVectors(zAxis, direction_vector);

        if(a>0 && a <= end){
          myMesh.current.position.x = from.x*(1-dec_a) + to.x* (dec_a);
          myMesh.current.position.y = from.y*(1-dec_a) + to.y* (dec_a);
          myMesh.current.position.z = from.z*(1-dec_a) + to.z* (dec_a);
        }
        if(a>= 1 && a<= end+explosion_duration){
          //shield.current.material.opacity = end+explosion_duration - a
          shield.current.visible = true

          if (shield.current) {
            const newGeometry = new THREE.SphereGeometry(
              0.2, // radius
              6, // width segments
              6, // height segments
              0,
              2*Math.PI,
              0, // theta start
              (0.8-(a% 1)/2)// theta length
            );
            // Dispose the old geometry to avoid memory leaks
            shield.current.geometry.dispose();
            // Assign the new geometry
            shield.current.geometry = newGeometry;
            //shield.current.lookAt(0,0,0)
            //if (to_alpha != 0 || to_beta != 0){
            //  shield.current.rotation.y = - to_alpha;
            //  shield.current.rotation.z = to_beta +Math.PI/2;}
            //else{
            //  shield.current.rotation.y = - from_alpha ;
            //  shield.current.rotation.z = from_beta-Math.PI/2;
            //}
            //shield.current.rotation.x = Math.atan((from.z - to.z)/(from.y - to.y));

            //shield.current.rotation.z = Math.atan((from.x - to.x)/(from.y - to.y));// Math.atan((from.z - to.z)/ Math.sqrt((from.x - to.x)*(from.x - to.x) + (from.y - to.y) * (from.y - to.y)));
            //shield.current.rotation.z =   Math.PI/4//Math.atan((from.y - to.y)/(from.z - to.z));
            //shield.current.rotation.z = Math.atan((from.x - to.x)/(from.z - to.z));
            shield.current.quaternion.copy(quaternion);
          }


          //shield.current.lookAt(0, 0, 0);
          
        }
        if(a>=end+explosion_duration){
          shield.current.visible = false
        }
  
        if(a <= end && a> 0){
          myMesh.current.visible = true
        }
        else{
          myMesh.current.visible = false
        }
      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
      
      <mesh ref={shield} visible={false} renderOrder={2}>
        
          <sphereGeometry  args ={[0.5, 10,10, 0, Math.PI]}/>
          <meshBasicMaterial depthWrite={true} depthTest={true} attach="material" color={'#FEDBB7'} side={THREE.DoubleSide}/>
      </mesh>

      <mesh ref={myMesh} visible={false}>
          <sphereGeometry args ={[0.03, 4,0]}/>
          <meshBasicMaterial attach="material" color={'white'}  transparent={true} />
      </mesh>

      </>
    );          // 4, 5 arg - theta start and theta length
          // 6, 7 arg - Phi start, phi length (controls the vertical segment).

  }
  

  const JetsAnimation: React.FC<AnimationProps> = ({ args }) => {
  
    const rocket2 :any = React.useRef();
    const explosion:any = React.useRef();
    const rocket2indicator:any = React.useRef();
      
    const jets :any = React.useRef();
    const jets_indicator:any = React.useRef();
    const delay = 0
    const shift = 0
    const end = 2.5
    const return_back = 1.6
    const group_end = 3
    const explosion_duration = 1
    const explosion_scale_speed = 3
    const explosion_start_radius = 1.2
    const clock = React.useRef(new THREE.Clock());
    let from_alpha = 0

    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let backhook = 0//0.1

    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    let a = 0
    let elapsedTime = 0
    let time_shift = 0
    let linear_a = 0

    let shift_x = 0 
    let shift_y = 0
    let shift_z = 0
    let last_x = 0
    let last_y = 0
    let last_z = 0
    let shifted_a = 0
    let group_shifted_a = 0
    let x_rand = (Math.random() -0.5) / 3
    let y_rand = (Math.random() -0.5) / 3
    let z_rand = (Math.random() -0.5) / 3

    useFrame(() => {
      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {
        elapsedTime = clock.current.getElapsedTime();
  
        linear_a = elapsedTime*0.7 - delay - shift;
        if (linear_a < backhook / 2 ){
          a = - linear_a
        }
        else{
          a = linear_a - backhook
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)

        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to = getCoords(to_alpha, to_beta, args.to.radius)

        shift_x = Math.sin((1 - a/end)*3)*a/end
        shift_y = Math.sin((1 - a/end)*3)*a/end
        shift_z = Math.sin((1 - a/end)*3)*a/end

        explosion.current.position.x = to.x *(1-shift) + from.x* shift;
        explosion.current.position.y = to.y*(1-shift) + from.y* shift;
        explosion.current.position.z = to.z*(1-shift) + from.z* shift;
        if(a  < return_back){

          jets.current.position.x = from.x *(1 - a/end) + to.x* a/end + shift_x;
          jets.current.position.y = from.y *(1 - a/end) + to.y* a/end+ shift_y;
          jets.current.position.z = from.z *(1 - a/end) + to.z* a/end + shift_z;
          jets.current.visible = true
          if (jets_indicator.current){
            jets_indicator.current.style.display = 'block';  
            if (rocket2indicator.current){
              rocket2indicator.current.style.display = 'none';  
            }
          }
          last_x = jets.current.position.x
          last_y = jets.current.position.y
          last_z = jets.current.position.z

        }
        if( a  >= return_back && a  < group_end){
          group_shifted_a = (a - return_back)/(group_end - return_back)

          shift_x = group_shifted_a * (1 -  group_shifted_a)* 2 * x_rand
          shift_y = group_shifted_a * (1 -  group_shifted_a)* 20 * y_rand
          shift_z = group_shifted_a * (1 -  group_shifted_a)* 2 * z_rand

          jets.current.position.x = last_x *(1 - group_shifted_a) + from.x* Math.pow(group_shifted_a,3) + shift_x;
          jets.current.position.y = last_y *(1 - group_shifted_a) + from.y* Math.pow(group_shifted_a,3) + shift_y;
          jets.current.position.z = last_z *(1 - group_shifted_a) + from.z* Math.pow(group_shifted_a,3) + shift_z;
          jets.current.visible = true
          if (jets_indicator.current){
            jets_indicator.current.style.display = 'block';  
          }
        }

        if( a  >= return_back && a  < end){
          shifted_a = (a - return_back)/(end - return_back)
          rocket2.current.position.x = last_x *(1 -  Math.pow(shifted_a,3)) + to.x* Math.pow(shifted_a,3);
          rocket2.current.position.y = last_y *(1 - Math.pow(shifted_a,3)) + to.y* Math.pow(shifted_a,3);
          rocket2.current.position.z = last_z *(1 - Math.pow(shifted_a,3)) + to.z* Math.pow(shifted_a,3);
          rocket2.current.visible = true
          if (rocket2indicator.current){
            rocket2indicator.current.style.display = 'block';  
          }
        
        }
        
        if( a  >= end){
          rocket2.current.visible = false
          if (rocket2indicator.current){
            rocket2indicator.current.style.display = 'none';  
          }

        }
        if( a >= group_end - 0.02){
          jets.current.visible = false

          if (jets_indicator.current){
            jets_indicator.current.style.display = 'none';  
          }
        }


        if(a>= end && a<= end+explosion_duration){
          //mark.current.material.opacity = end+explosion_duration - a
          if(a -end<  explosion_duration/4){
            explosion.current.material.opacity = 1
          }
          else{
            explosion.current.material.opacity = 1  - (a-end - explosion_duration/4)* 4/explosion_duration 
          }
          explosion.current.visible = true
        }
        if(a>=end+explosion_duration){
          explosion.current.visible = false
        }

      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
        <group 
            ref={rocket2} // Use the ref from the array
            visible={false}
          >
            <Html
              zIndexRange={[10, 0]} 
              ref={rocket2indicator}
              visible={false}
              >
                <div
                  className="radar-animation-indicator noselect"
                >
                  <small className={args.from.enemy? "row red-highlight blink":"row cyan-highlight blink"}>ПУСК</small>
                  <div className={args.from.enemy? 'rocket-aim-styler red': 'rocket-aim-styler cyan'}/>
                </div>
            </Html>
            <mesh >
                <sphereGeometry args ={[0.03, 4,0]}/>
                <meshBasicMaterial attach="material" color={'white'} />
            </mesh>
          </group>

          <group 
            ref={jets} // Use the ref from the array
            visible={false}
          >
            <Html
              zIndexRange={[10, 0]} 
              ref={jets_indicator}
              visible={false}
              >
                <div
                  className="radar-animation-indicator noselect"
                >
                  <small className={args.from.enemy? "row red-highlight blink center-row":"row cyan-highlight blink center-row"}>УДАРН</small>
                  <small className={args.from.enemy? "row red-color center-row":"row cyan-color center-row"}>ЗВЕНО</small>
                  <div className={args.from.enemy? 'triangle red': 'triangle'}/>
                </div>
            </Html>
          </group>

      <mesh ref={explosion} visible={false} position={[pos_x,pos_y,pos_z]}>
          <sphereGeometry args ={[0.3, 12,12]}/>
          <meshBasicMaterial attach="material" transparent={true} color={'white'} />
      </mesh>
      </>
    );
  }


  const RocketAnimation: React.FC<AnimationProps> = ({ args }) => {
  
    const rocket2 :any = React.useRef();
    const explosion:any = React.useRef();
    const rocket2indicator:any = React.useRef();
    const delay = 0
    const shift = 0
    const end = 1.5
    const explosion_duration = 1
    const explosion_scale_speed = 3
    const explosion_start_radius = 1.2
    const clock = React.useRef(new THREE.Clock());
    let from_alpha = 0

    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let backhook = 0.1

    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    let a = 0
    let elapsedTime = 0
    let time_shift = 0
    let linear_a = 0

    let shift_x = 0 
    let shift_y = 0
    let shift_z = 0

    useFrame(() => {
      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {
        elapsedTime = clock.current.getElapsedTime();
  
        linear_a = elapsedTime*0.7 - delay - shift;
        if (linear_a < backhook / 2 ){
          a = - linear_a
        }
        else{
          a = linear_a - backhook
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)

        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to = getCoords(to_alpha, to_beta, args.to.radius)

        shift_x = Math.sin((1 - a/end)*3)*a/2/end
        shift_y = Math.sin((1 - a/end)*3)*a/2/end
        shift_z = Math.sin((1 - a/end)*3)*a/2/end

        explosion.current.position.x = to.x *(1-shift) + from.x* shift;
        explosion.current.position.y = to.y*(1-shift) + from.y* shift;
        explosion.current.position.z = to.z*(1-shift) + from.z* shift;

        if(a < end){
          rocket2.current.position.x = from.x *(1 - Math.pow(a/end,3)) + to.x* Math.pow(a/end,3) + shift_x;
          rocket2.current.position.y = from.y *(1 - Math.pow(a/end,3)) + to.y* Math.pow(a/end,3)+ shift_y;
          rocket2.current.position.z = from.z *(1 - Math.pow(a/end,3)) + to.z* Math.pow(a/end,3) + shift_z;
          rocket2.current.visible = true
          if (rocket2indicator.current){
            rocket2indicator.current.style.display = 'block';  
          }
          //if(linear_a % 0.15 > 0.07){
          //  rocket2.current.visible = true
          //}
          //else{
          //  rocket2.current.visible = true
          //}
        
        }else{
          rocket2.current.visible = false
          if (rocket2indicator.current){
            rocket2indicator.current.style.display = 'none';  
          }
        }


        if(a>= end && a<= end+explosion_duration){
          //mark.current.material.opacity = end+explosion_duration - a
          if(a -end<  explosion_duration/4){
            explosion.current.material.opacity = 1
          }
          else{
            explosion.current.material.opacity = 1  - (a-end - explosion_duration/4)* 4/explosion_duration 
          }
          explosion.current.visible = true
        }
        if(a>=end+explosion_duration){
          explosion.current.visible = false
        }

      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
        <group 
            ref={rocket2} // Use the ref from the array
            visible={false}
          >
            <Html
              zIndexRange={[10, 0]} 
              ref={rocket2indicator}
              visible={false}
              >
                <div
                  className="radar-animation-indicator noselect"
                >
                  <small className={args.from.enemy? "row red-highlight blink":"row cyan-highlight blink"}>ПУСК</small>
                  <div className={args.from.enemy? 'rocket-aim-styler red': 'rocket-aim-styler cyan'}/>
                </div>
            </Html>
            <mesh >
                <sphereGeometry args ={[0.03, 4,0]}/>
                <meshBasicMaterial attach="material" color={'white'} />
            </mesh>
          </group>

      <mesh ref={explosion} visible={false} position={[pos_x,pos_y,pos_z]}>
          <sphereGeometry args ={[0.3, 12,12]}/>
          <meshBasicMaterial attach="material" transparent={true} color={'white'} />
      </mesh>
      </>
    );
  }

  
  const AntiRocketAnimation: React.FC<AnimationProps> = ({ args }) => {
  
    const rocket2 :any = React.useRef();
    const rocket2indicator :any = React.useRef();
    const mark:any = React.useRef();
    const delay = 0
    const shift = 0
    const end = 1
    const original_end = 1.5
    const explosion_duration = 4
    const clock = React.useRef(new THREE.Clock());
    let from_alpha = 0

    let from_beta = 0
    let from = {x:0, y: 0, z:0}
    let backhook = 0.1

    let to_alpha = 0
    let to_beta = 0
    let to = {x:0, y: 0, z:0}
    let a = 0
    let elapsedTime = 0
    let time_shift = 0
    let linear_a = 0
    let last_a = 0
    let shift_x = 0 
    let shift_y = 0
    let shift_z = 0

    useFrame(() => {
      time_shift = Date.now()*animation_speed_coef;

      if(args && args.from && args.to)
      {
        elapsedTime = clock.current.getElapsedTime();
  
        linear_a = elapsedTime*0.7 - delay - shift;
        if (linear_a < backhook / 2 ){
          a = - linear_a
        }
        else{
          a = linear_a - backhook
        }

        from_alpha = args.from.alpha + time_shift * args.from.alpha_delta / 2;
        from_beta = args.from.beta + time_shift * args.from.beta_delta / 2;
        from = getCoords(from_alpha, from_beta, args.from.radius)

        to_alpha = args.to.alpha + time_shift * args.to.alpha_delta / 2;
        to_beta = args.to.beta + time_shift * args.to.beta_delta / 2;
        to = getCoords(to_alpha, to_beta, args.to.radius)


        if(a <= end){
          shift_x = Math.sin((1 - a/original_end)*3)*a/2/original_end
          shift_y = Math.sin((1 - a/original_end)*3)*a/2/original_end
          shift_z = Math.sin((1 - a/original_end)*3)*a/2/original_end
          last_a = a
          rocket2.current.position.x = from.x *(1 - Math.pow(a/original_end,3)) + to.x* Math.pow(a/original_end,3) + shift_x;
          rocket2.current.position.y = from.y *(1 - Math.pow(a/original_end,3)) + to.y* Math.pow(a/original_end,3)+ shift_y;
          rocket2.current.position.z = from.z *(1 - Math.pow(a/original_end,3)) + to.z* Math.pow(a/original_end,3) + shift_z;
          rocket2.current.visible = true

          mark.current.position.x = from.x *(1 - Math.pow(last_a/original_end,3)) + to.x* Math.pow(last_a/original_end,3) + shift_x;
          mark.current.position.y = from.y *(1 - Math.pow(last_a/original_end,3)) + to.y* Math.pow(last_a/original_end,3) + shift_y;
          mark.current.position.z = from.z *(1 - Math.pow(last_a/original_end,3)) + to.z* Math.pow(last_a/original_end,3) + shift_z;
          //if(linear_a % 0.15 > 0.07){
          //  rocket2.current.visible = true
          //}
          //else{
          //  rocket2.current.visible = false
          //}
        
        }else{
          rocket2.current.visible = false
        }


        if(a>= end&& a<= end+explosion_duration){
          //mark.current.material.opacity = end+explosion_duration - a

          mark.current.visible = true
        }
        if(a>=end+explosion_duration){
          mark.current.visible = false
          rocket2indicator.current.style.display = 'none';  
        }

  
        if(a <= end){
          rocket2.current.visible = true

        }
        else{
          rocket2.current.visible = false
        }
      }
  
    });
  
    let pos_x = 10
    let pos_y = 10
    let pos_z = 10

    return (
      <>
      <group 
            ref={rocket2} // Use the ref from the array
            visible={false}
          >
            <Html
              zIndexRange={[10, 0]} 
              ref={rocket2indicator}
              visible={false}
              >
                <div
                  className="radar-animation-indicator noselect"
                >
                  <small className={args.from.enemy? "row red-highlight blink":"row cyan-highlight blink"}>ПУСК</small>
                  <small className={args.from.enemy? "row red-color":"row cyan-color"}>СБИТ</small>
                  <div className={args.from.enemy? 'rocket-aim-styler red': 'rocket-aim-styler cyan'}/>
                </div>
            </Html>
            <mesh >
                <sphereGeometry args ={[0.03, 4,0]}/>
                <meshBasicMaterial attach="material" color={'white'} />
            </mesh>
      </group>
      <sprite ref={mark} 
            position={[pos_x,pos_y,pos_z]}
            scale={0.2} visible={false}
            >
          <spriteMaterial map={texture_rocket_block}/>
            </sprite>
      </>
    );
  }


  const AllRockets = () => {
    return (<>
    {successfull_attack && successfull_attack.length > 0 ?
      <>
        {successfull_attack.map((e:any) =>{

          return <AttackAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`rocket_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    {
      successfull_ew_pulse && successfull_ew_pulse.length > 0 ?
      <>
        {successfull_ew_pulse.map((e:any) =>{

          return <EwPulseAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`ew_pulse_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    {
      successfull_heat_traps && successfull_heat_traps.length > 0 ?
      <>
        {successfull_heat_traps.map((e:any) =>{

          return(
            <>
              <HeatTrapsAnimation 
              args={{ 'from': e.from, 'to': e.to }}
              key={`ht1_${e.id}`} // Use a unique identifier for the key
                />
              <HeatTrapsAnimation 
              args={{ 'from': e.from, 'to': e.to }}
              key={`ht2_${e.id}`} // Use a unique identifier for the key
                />
            </>)

          }
        )}
      </>
      :
      <></>
    }
    {blocked_attack && blocked_attack.length > 0 ?
      <>
        {blocked_attack.map((e:any) =>{
          return <BlockedAttackAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`rocket_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    {successfull_overpower && successfull_overpower.length > 0 ?
      <>
        {successfull_overpower.map((e:any) =>{
          return <RocketAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`rocket_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    {successfull_squadron && successfull_squadron.length > 0 ?
      <>
        {successfull_squadron.map((e:any) =>{
          return <JetsAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`rocket_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    {blocked_overpower && blocked_overpower.length > 0 ?
      <>
        {blocked_overpower.map((e:any) =>{
          return <AntiRocketAnimation 
          args={{ 'from': e.from, 'to': e.to }}
          key={`rocket_${e.id}`} // Use a unique identifier for the key
            />
          }
        )}
      </>
      :
      <></>
    }
    </>) 

}

  return (
    <>
      <AllRockets/>
    </>
  );
}