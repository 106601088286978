import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { User } from '../models/types';

const initialState: User = {
  id: 0,
  name:"",
  /*
  hash_id:"",
  link_id:"",

  telegram_id:0,
  telegram_first_name:"",
  telegram_last_name:"",
  telegram_username:"",
  telegram_language_code:"en",*/
  
  hp: 1,
  ep: 1,
  sp: 1,
  ai: 1,
  morale: 1,
  supply: 1,
  order: 1,
  is_captain: true,

  faith: 1,
  aggression: 1,
  diplomacy: 1,
  //location: null,
  //destination: null,
  hyperpoints: 0,

  karma: 0,
  frags: 0,
  deaths: 0,
  battles:[],

  //groups:[],

  equips:[],
  hangar_items:[],
  team: null,
  total_stats:null,
  is_in_fight: false,
  is_in_voyage: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
      setUser: (state, action) => {
        state.id = action.payload?.id;
        state.name = action.payload?.name;
        /*
        state.hash_id = action.payload?.hash_id;
        state.link_id = action.payload?.link_id;
        
        state.telegram_id = action.payload?.telegram_id;
        state.telegram_first_name = action.payload?.telegram_first_name;
        state.telegram_last_name = action.payload?.telegram_last_name;
        state.telegram_username = action.payload?.telegram_username;
        state.telegram_language_code = action.payload?.telegram_language_code;
        */
        state.hp = action.payload?.hp;
        state.ep = action.payload?.ep;
        state.sp = action.payload?.sp;
        state.ai = action.payload?.ai;
        state.morale = action.payload?.morale;
        state.supply = action.payload?.supply;
        state.order = action.payload?.order;
        state.karma = action.payload?.karma;

        state.faith = action.payload?.faith;
        state.aggression = action.payload?.aggression;
        state.diplomacy = action.payload?.diplomacy;
        state.hyperpoints = action.payload?.hyperpoints;

        state.is_captain = action.payload?.is_captain;
        state.frags = action.payload?.frags;
        //state.location = action.payload?.location;
        //state.destination = action.payload?.destination;
        state.deaths = action.payload?.deaths;
            state.battles = action.payload?.battles;
            //state.groups = action.payload?.groups;
            state.equips = action.payload?.equips;
            state.hangar_items = action.payload?.hangar_items;
            state.total_stats = action.payload?.total_stats;
            state.team = action.payload?.team;
        state.is_in_fight = action.payload?.is_in_fight;
        state.is_in_voyage = action.payload?.is_in_voyage;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
