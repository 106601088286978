import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { Battle } from '../models/types';

const initialState: Battle = {
  id: 0,
  step_num: -1,
  step_end_date: new Date().getTime(), // Set the initial value to the current timestamp
  info: '',
  status: '',
  result: null,
  teams: [],
  battle_logs: []
};

export const battleSlice = createSlice({
  name: 'battle',
  initialState,
  reducers: {
      setBattle: (state, action) => {
        state.id = action.payload?.id;

        
        state.step_num = action.payload?.step_num;
        state.step_end_date = new Date(action.payload?.step_end_date).getTime();
        state.info = action.payload?.info;
        state.status = action.payload?.status;
        state.result = action.payload?.result;
        state.teams = action.payload?.teams;
        state.battle_logs = action.payload?.battle_logs;
       
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setBattle } = battleSlice.actions;

export default battleSlice.reducer;
