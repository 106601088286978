// store.ts
import { configureStore } from "@reduxjs/toolkit";
import userSlice from '../slices/userSlice'
import battleSlice from '../slices/battleSlice'
import teamSlice from '../slices/teamSlice'
import localisation from "../slices/localisationSlice";
import posArraySlice from "../slices/posArraySlice";
import navMapSlice from "../slices/navMapSlice";
import userLocationsSlice from "../slices/userLocationsSlice";
import userReputationsSlice from "../slices/userReputationsSlice";
import screenTabSlice from "../slices/screenTabSlice";
import availableItemsSlice from "../slices/availableItems";
import availableEventsSlice from "../slices/availableEvents";
import showShipCardIdSlice from "../slices/showShipCardIdSlice";
import voyageHistorySlice from "../slices/voyageHistorySlice";
import teamPathSlice from "../slices/teamPathSlice";
import sound from "../slices/soundSlice";

const store = configureStore({
    reducer: {

        user: userSlice,
        availableItems: availableItemsSlice,
        availableEvents: availableEventsSlice,
        battle: battleSlice,
        localisation: localisation,
        posArray: posArraySlice,
        navMap: navMapSlice,
        userLocations: userLocationsSlice,
        userReputations: userReputationsSlice,
        team: teamSlice,
        screenTab: screenTabSlice,
        showShipCardId: showShipCardIdSlice,
        voyageHistory : voyageHistorySlice,
        teamPath : teamPathSlice,
        sound: sound,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
