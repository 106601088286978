import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { Item } from '../models/types';

const initialState: Item[] = [];

export const availableItemsSlice = createSlice({
  name: 'availableItems',
  initialState,
  reducers: {
    setAvailableItems: (state, action: PayloadAction<Item[]>) => {
      // Modify the existing state by updating it with the new payload
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
});
export const { setAvailableItems } = availableItemsSlice.actions;

export default availableItemsSlice.reducer;
