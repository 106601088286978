import React, { useRef, useState, useEffect } from 'react';


interface TypewriterProps {
  text: string;
}

  export default function RandomMarkerLabelBackground ({ text }: TypewriterProps) {
    return <>
        {
          [...Array(10)].map((e, i) => {
            let x = Math.floor(Math.random() * 100)
            let y = Math.floor(Math.random() * 100)
            return <><div 
                    className= 'anomaly-alert'
                    style={{
                    left: x + '%', 
                    top: y  + '%',
                    animationDelay: Math.random()*5 +'s'}}
                    >
                      <small className='anomaly-label'></small>
                      <div className='anomaly' style={{animationDelay: Math.random()*5 +'s'}}></div>
                    </div>
            </>
        })
        }
    </>
  }