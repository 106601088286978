import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { UserReputations } from '../models/types';

const initialState: UserReputations = {
  reputations: []
};

export const userReputationsSlice = createSlice({
  name: 'UserReputations',
  initialState,
  reducers: {
      setUserReputations: (state, action) => {
        state.reputations = action.payload?.reputations;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setUserReputations } = userReputationsSlice.actions;

export default userReputationsSlice.reducer;
