import React, { useRef, useState, useEffect} from 'react';
import ky from 'ky';
import EquipCard from '../components/inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
//import items from '../utils/items';
import {setUser} from '../slices/userSlice';
import {setTeam} from '../slices/teamSlice';
import {setAvailableItems} from '../slices/availableItems';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;



export default function Inventory() {
  const dispatch = useDispatch();
  const [equipModal, setEquipModal] = useState(0)
  const [hullModal, setHullModal] = useState(0)
  const [crewModal, setCrewModal] = useState(0)
  const [changeItemTargetId, setChangeItemTargetId] = useState(0)

  const user = useSelector((state:RootState)=> state.user);
  const team = useSelector((state:RootState)=> state.team);
  const available_items = useSelector((state:RootState)=> state.availableItems);
  //const available_equips :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_hulls :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_crews :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  const available_equips = available_items?.filter((el:any) => el.item_type === "module")

  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const link_id = urlParams.get('link_id');
  //function getItemJson(card_id:number, item_type:string){
  //  return available_items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  useEffect(() => {
    fetchTeam()
  }, [dispatch]);

  console.log('srav',user?.team?.id, team?.id)
  async function fetchTeam() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/teams/get_team?link_id=${link_id}`;

      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log("team result", result)
      dispatch(setTeam(result));
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        return "■ ".repeat(square_num)
    }
    return ""
}
console.log('team', team)
function crossIndicator(square_num:number){
  if (square_num && square_num > 0)
  {
      return "x ".repeat(square_num)
  }
  return ""
}

  return (
    <>
    <div className='team-page'>

    <div className='team-overview'>
        <div className='breakable-grid'>
          <div className='non-breaking-half'>
              <div className='line'>
                <div className='non-flex-block'>
                <h2>{team?.karma}</h2> 
                <p>ОЧКИ</p>
                <p>КАРМЫ</p>
                </div>
                <div className='flex-block hypderdive-circular-map'>
                    <div className='layer-5 rotation-transform'></div>
                    <div className='layer-4 rotation-transform '></div>
                    <div className='layer-3 rotation-transform '></div>
                    <div className='layer-2 rotation-transform'></div>
                    <div className='layer-1 rotation-transform'></div>

                    <div className='map-grid rotation-transform'> </div>

                    <div className='layer-0 rotation-transform'></div>

                    <div className='border-wave-top'></div>
                    <div className='border-wave-bottom'></div>
                    <div className={'player-icon-layer-' + (team?.dive_level) +' pulsate'}></div> 

                    <div  className='title-top'><small>{team?.dive_level} слой гиперпространства</small></div>
                    <div  className='title-bottom'><small>{5 - team?.steps_after_dive} дня до перехода</small></div>

                </div>
              </div>
              <div className='line'>
               <div className='flex-block'>
                <p>{team?.morale} мораль</p>
                <p>{team?.supply} снабжение</p>
                <p>{team?.order} субординация</p>
                
                </div>
                <div className='flex-block'>
                <p>{team?.aggression} устрашение</p>
                <p>{team?.diplomacy} дипломатия</p>
                <p>{team?.faith} вера</p>
                
                </div>
              </div>
          </div>
          <div className='non-breaking-half'>
              <div className='full-block'>

              <p>{team?.freight} / {team?.freight_limit} груз</p>
              <p className='inactive'>x биолаборатория</p>
              <p className='inactive'>x ремонтный модуль</p>
              <p className='inactive'>x буровой челнок</p>
              <p className='inactive'>x эко-хранилище</p>
              <p className='inactive'>x осадная артиллерия</p>
              <p className='inactive'>x буксир</p>

              </div>
          </div>
        </div>
        <div className='team-showroom'>
          {
            team?.users.map((u, index)=>{
                let hull = u?.equips?.find((el:any) => el.item_type === "hull")
                
                return(<div className={index % 2 == 0?
                  'ship-card' : 'ship-card reversed'}>
                <div className='indicators'>
                  <div className='indicators-name'> 
                  <p>КОР</p>
                  <p>ЭНР</p>
                  <p>СПЦ</p>
                  <p>ИИ</p>
                  </div>
                  <div className='indicators-count'>
                  <p>{u?.hp}/{u?.total_stats?.hp}</p>
                  <p>{u?.ep}/{u?.total_stats?.ep}</p>
                  <p>{u?.sp}/{u?.total_stats?.sp}</p>
                  <p>{u?.ai}/{u?.total_stats?.ai}</p>

                  </div>
                </div>
                <div className='circle'> </div>
                <img className='ship-img' src={hull?.img}></img>

            </div>)
              })
          }

          </div>
    </div>
    </div>
        

    <div className='hud no-transparent'>
        <div className='order-btn'>
          
        </div>
    </div>
    </>
    //      <axesHelper />

  )
}
