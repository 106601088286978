import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { Event } from '../models/types';

const initialState: Event[] = [];

export const availableEventsSlice = createSlice({
  name: 'availableEvents',
  initialState,
  reducers: {
    setAvailableEvents: (state, action: PayloadAction<Event[]>) => {
      // Modify the existing state by updating it with the new payload
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
});
export const { setAvailableEvents } = availableEventsSlice.actions;

export default availableEventsSlice.reducer;
