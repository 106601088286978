import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { NavMap } from '../models/types';

const initialState: NavMap = {
  stations:[]
};

export const navMapSlice = createSlice({
  name: 'navMap',
  initialState,
  reducers: {
      setNavMap: (state, action) => {
        state.stations = action.payload?.stations;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setNavMap } = navMapSlice.actions;

export default navMapSlice.reducer;
