const skills : any = 
[
    {
        "name": "Атака",
        "ref_name" :"atk",
        "short_name":"АТК",
        "small_description": "-1 ЭНР",
        "description": "Зенитный калибр, наносит урон. Может быть заблокирован, сбивает управляемые ракеты.",
        "priority":5,
        "reaim": 0,
        "target_needed": 1,
        "friendly_fire": 0,
        "mass_initiation":0,
        "initiation": 0,
        "shield":0,
        "counter_shield":0,
        "counter_shield_protection":0,
        "can_interrupt":1,
        "can_be_interrupted":0,
        "cost":{
            "ep": 1,
        },
        "damage":{
            "hp": 1,
        },
        "attribute_required" : 0
    },
    {
        "name": "Щиты",
        "ref_name" :"def",
        "short_name":"ЩИТ",
        "small_description": "+1 ЭНР",
        "description": "Щит полностью блокирует обычные атаки и восстанавливает энергию. Контратака при полном заряде ИИ.",
        "priority":10,
        "reaim": 1,
        "target_needed": 1,
        "friendly_fire": 0,
        "mass_initiation":0,
        "initiation": 0,
        "shield":1,
        "counter_shield":1,
        "counter_shield_protection":0,
        "can_interrupt":0,
        "can_be_interrupted":0,
        "cost":{
            "ep": -1,
        },
        "damage":{
        },
        "attribute_required" : 0
    },
    {
        "name": "Торпеды",
        "ref_name" :"over",
        "short_name":"ПУСК",

        "small_description": "-2 ЭНР",
        "description": "Тяжелые управляемые ракеты. Пробивают щиты, могут быть сбиты.",
        "priority":1,
        "reaim": 0,
        "target_needed": 1,
        "friendly_fire": 0,
        "mass_initiation":0,
        "initiation": 0,
        "shield":0,
        "counter_shield":1,
        "counter_shield_protection":0,
        "can_interrupt":0,
        "can_be_interrupted":1,
        "cost":{
            "ep": 2,
        },
        "damage":{
            "hp": 1,
        },
        "attribute_required" : 0
    },
    {
        "name": "РЭБ импульс",
        "ref_name" :"ew_pulse",
        "short_name":"РЭБ",

        "small_description": "-1 СПЦ",
        "description": "Сильные помехи скрывают союзников, но наводят орудия противников на ваш корабль. Активирует щит.",
        "priority":11,
        "reaim": 1,
        "target_needed": 0,
        "friendly_fire": 0,
        "mass_initiation":1,
        "initiation": 0,
        "shield":1,
        "counter_shield":0,
        "counter_shield_protection":0,
        "can_interrupt":0,
        "can_be_interrupted":0,
        "cost":{
            "sp": 1,
        },
        "damage":{
        },
        "attribute_required" : "ew_pulse",
    },
    {
        "name": "ЭМИ-торпеды",
        "ref_name" :"emi_over",
        "short_name":"ЭМИ",

        "small_description": "-1 СПЦ, -1 ЭНР",
        "description": "Торпеды, позволяющие перегрузить системы противника и снизить запас энергии на 2.",
        "priority":2,
        "reaim": 0,
        "target_needed": 1,
        "friendly_fire": 0,

        "mass_initiation":0,
        "initiation": 0,
        "shield":0,
        "counter_shield":1,
        "counter_shield_protection":0,
        "can_interrupt":0,
        "can_be_interrupted":1,
        "cost":{
            "sp": 1,
            "ep": 1
        },
        "damage":{
            "ep": 2,
        },
        "attribute_required" : "emi_over",
    },
    {
        "name": "Ударное звено",
        "ref_name" :"squadron",
        "short_name":"ИСТ",

        "small_description": "-2 СПЦ",
        "description": "Ударное звено истребителей. Пробивают щиты, могут быть сбиты, наносят двойной урон",
        "priority":2,
        "reaim": 0,
        "target_needed": 1,
        "friendly_fire": 0,

        "mass_initiation":0,
        "initiation": 0,
        "shield":0,
        "counter_shield":1,
        "counter_shield_protection":0,
        "can_interrupt":0,
        "can_be_interrupted":1,
        "cost":{
            "sp": 2,
        },
        "damage":{
            "hp": 2,
        },
        "attribute_required" : "squadron"
    },
    {
        "name": "ЭМ-ловушки",
        "ref_name" :"traps",
        "short_name":"ЛОВ",

        "small_description": "-1 СПЦ",
        "description": "Радио-тепловые ловушки совместно с щитами могут защитить от всех типов атак.",
        "priority":10,
        "reaim": 1,
        "target_needed": 1,
        "friendly_fire": 0,

        "mass_initiation":0,
        "initiation": 0,
        "shield":1,
        "counter_shield":0,
        "counter_shield_protection":1,
        "can_interrupt":0,
        "can_be_interrupted":1,
        "cost":{
            "sp": 1,
        },
        "damage":{
        },
        "attribute_required" : "em_traps"
    },

]


export default skills