import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { voyageHistory } from '../models/types';

const initialState: voyageHistory = {
  events: []
};

export const teamPathSlice = createSlice({
  name: 'teamPath',
  initialState,
  reducers: {
      setTeamPath: (state, action) => {
        state.events = action.payload?.events;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setTeamPath } = teamPathSlice.actions;

export default teamPathSlice.reducer;
