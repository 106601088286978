import React, { useRef, useState, useEffect, useMemo } from 'react';

import ky from 'ky';
import { useSelector, useDispatch } from 'react-redux';
import './App.css'
// import styles from "./About.module.css"
import { useNavigate } from "react-router-dom";


const API_BASE_URL = process.env.REACT_APP_BASE_URL;



export default function TelegramAppLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  async function fetchHash(telegram_id : any, telegram_init_data : any) {
    try {
      // Add hash_id to the fetch request URL
      
      const requestUrl = `${API_BASE_URL}/players/users/get_hash_from_telegram_app/`;
      const result :any = await ky.put(requestUrl, { json: {telegram_id: telegram_id, telegram_data:telegram_init_data},  credentials: 'include' }).json();
      if (result){
        navigate(`/voyage?hash_id=${result?.hash_id}`)
      }

    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };


  useEffect(() => {
    // Retrieve the script tag by its ID
    const scriptTag = document.getElementById('tg_data');
    const scriptTag_id = document.getElementById('tg_id');
    if (scriptTag) {
      try {
        // Parse the JSON content
        const jsonData = JSON.parse(scriptTag.textContent || '{}');
        fetchHash(JSON.parse(scriptTag_id?.textContent || '{}'), jsonData);

      } catch (error) {
        console.error('Error parsing tg_data:', error);
      }
    }
  }, [dispatch]);

  return (
    <div className='hud no-opacity'>

    </div>
  );
}
