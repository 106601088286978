import React, { useEffect, useState } from 'react';
import ky from 'ky';
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { publicRoutes, /*userRoutes,*/} from '../routes';
import { isMobile } from 'react-device-detect';
import ErrorPage from '../pages/Errorpage';
//import AboutPage from '../pages/oldpages/Aboutpage';
import Battle from '../pages/Battle';
import Events from '../pages/Events';
import Planet from '../pages/Planet';
//import AboutPage4 from '../pages/Aboutpage4';
import TestInventory from '../pages/TestInventory';
//import Inventory from '../pages/Inventory';
import Voyage from '../pages/Voyage';
import StationsGraph from '../pages/StationsGraph';
import Team from '../pages/Team';
import WaitingRoom from '../pages/WaitingRoom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../store/store";
import { setUser } from '../slices/userSlice';
import {User} from '../models/types'
import TelegramAppLogin from '../pages/TelegramAppLogin';

function AppRouter(): React.ReactElement {
    const user = useSelector((state:RootState)=> state.user)
    let userId = user.id;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();


    const API_BASE_URL = process.env.REACT_APP_BASE_URL;



    useEffect(() => {
        const fetchUser = async () => {
          try {
            // Extract the hash_id from the URL query parameters
            const urlParams = new URLSearchParams(window.location.search);
            const hash_id = urlParams.get('hash_id');
            if(hash_id){
              // Add hash_id to the fetch request URL
              const requestUrl = `${API_BASE_URL}/players/get_user?hash_id=${hash_id}`;
              const result :any  = await ky.get(requestUrl, { credentials: 'include' }).json();
              dispatch(setUser(result));
            }
          } catch (error) {
            console.error('Could not fetch user:', error);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchUser();
      }, [dispatch, navigate]);
      


    if (isLoading) {
        return(
            <>
                </>
        )
    }

    return (
        <>
            <Routes>
                { publicRoutes.map(({ path, element }) => (
                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                    ))}
                {/*userRole &&
                    userRole === 'founder' &&
                    companyCreatorId !== userId &&
                    userRoutes.map(({ path, element }) => (
                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                    ))*/}
                <Route path='/telegram_app_login' element={<TelegramAppLogin />} />
                <Route path='/battle' element={<Battle />} />
                <Route path='/planet' element={<Planet />} />
                <Route path='/voyage' element={<Voyage />} />
                {/*<Route path='/stations_graph' element={<StationsGraph />} />*/}
                <Route path='/waiting_room' element={<WaitingRoom />} />
                {/*<Route path='/inventory' element={<Inventory/>} />*/}
                <Route path='/team' element={<Team/>} />
                <Route path='/testinventory' element={<TestInventory/>} />
                <Route path='/events' element={<Events/>} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </>
    );
}

export default AppRouter;
