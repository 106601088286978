import React, { useRef, useState, useEffect } from 'react';
/*
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";*/
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line } from "@react-three/drei";

/*#FEDBB7
#CBAF92
#A28C75
#82705E
#685A4B
#53483C
#423A30
#352E26
#2A251E
#221E18
Black
 */
import './App.css'
// import styles from "./About.module.css"
import { DoubleSide } from 'three'

const futuristicPursuitMessages = [
  "Lying on intercept course. Alpha1: 1.34, Beta1: 2.13",
  "Engaging warp drive for rapid pursuit.",
  "Tracking target at maximum velocity.",
  "Calculating optimal trajectory for interception.",
  "Initiating evasive maneuvers. Delta2: 0.87, Gamma3: 1.92",
  "Deploying countermeasures to evade enemy pursuit.",
  "Requesting backup for support in pursuit operation.",
  "Navigation systems locked onto target coordinates.",
  "Preparing for quantum jump to overtake the target.",
  "Enemy vessel detected. Preparing for pursuit engagement.",
  "AI onboard: All systems at optimal functionality.",
  "Status update: Pursuit operation proceeding as planned.",
  "Target vessel identified: Code name 'Black Nova.'",
  "Initiating stealth mode to avoid enemy detection.",
  "AI onboard: Recommending evasive actions to avoid enemy fire.",
  "Engaging turbo boosters for pursuit acceleration.",
  "Requesting satellite surveillance for target tracking.",
  "Updating pursuit team on enemy movements.",
  "AI onboard: Scanning for potential weak points on target vessel.",
  "Status update: Pursuit operation at full throttle.",
  "Incoming transmission: Friendly fleet providing support.",
  "Navigating asteroid field to lose enemy tail.",
  "AI onboard: Probability of successful pursuit - 85%.",
  "Updating pursuit team on enemy's last known coordinates.",
  "AI onboard: Recommending a change in pursuit strategy.",
  "Initiating cloaking device to become invisible to enemy sensors.",
  "Status update: Target vessel attempting evasive maneuvers.",
  "Enemy vessel identified: Class 7 interceptor.",
  "AI onboard: Recommending ion cannon activation for defense.",
  "Engaging tractor beam to immobilize the target.",
  "Requesting permission for boarding party deployment.",
  "AI onboard: Scanning for life forms on the target vessel.",
  "Status update: Boarding party successfully secured target vessel.",
  "AI onboard: Recommending quarantine protocol for captured crew.",
  "Preparing for return to base with the captured enemy vessel.",
  "AI onboard: Verifying the integrity of captured intelligence data.",
  "Updating pursuit team on the successful mission completion.",
  "AI onboard: Analyzing the captured data for valuable insights.",
  "Preparing for a quantum jump back to home base.",
  "AI onboard: Recommending a full debrief of the pursuit operation.",
  "Engaging warp drive for rapid return to the home base.",
  "Status update: Mission accomplished. Returning to base.",
  "AI onboard: Transmitting mission summary to the command center.",
  "AI onboard: Expressing gratitude for the cooperation during the pursuit.",
];

export default function WaitingRoom() {
  const [openCard, setOpenCard] = useState(0)
  const [acceptModal, setAcceptModal] = useState(0)
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const [radarActive, setRadarActive] = useState(false); // Specify the type as string[]



  function AutoChatGenerator() {
    const first_messages=["Order to intercept...",
    `The turn-based battle will be displayed on a radar projection`,
    `In-battle options are:\n
    [Shields / Aim] - block any attack, restore energy and focus on target,\n 
    [Attack] - costs energy and knocks down a charged attack, 
    [Charged Attack] - costs two energy and breaks through a shield
  `]

    const [messages, setMessages] = useState<string[]>(["Order IC-1: Intercept course", ...first_messages]); // Specify the type as string[]

    // Function to generate a random message
    const generateRandomMessage = () => {
      const randomMessage = futuristicPursuitMessages[Math.floor(Math.random() * futuristicPursuitMessages.length)];
      return randomMessage;
    };
  
    // Function to add a new message to the list
    const addMessage = () => {
      let randomMessage = generateRandomMessage();
      setMessages((prevMessages) => [randomMessage,...prevMessages]);
    };
  
    useEffect(() => {
      // Add a new message every 3 seconds
      const messageInterval = setInterval(() => {
        addMessage();
      }, 3500);
  
      return () => {
        clearInterval(messageInterval);
      };
    }, []);
  
    return (
      <>
        {radarActive ?
          <>
            <div className='header pulsate'>////// WARNING //////</div>
              <div className="auto-chat">
              {messages.map((message, index) => (
                <div className={messages.length - 1 - first_messages.length == index ? "chat-message warning" : index == 0? "chat-message primary": "chat-message secondary"} key={"msg-num-"+index}>
                  <p>{message}</p>
                </div>
              ))}
            </div> 
          </> :
          <>
          <div className='header '>HEAT SIGNATURES DETECTED</div>
            <div className="auto-chat">
              {first_messages.map((message, index) => (
                <div className={ index == 0? "chat-message primary": "chat-message secondary"} key={"msg-num-"+index}>
                  <p>{message}</p>
                </div>
              ))}
            </div> 
          </>
        }
      </>
    );
  }

  return (
  <>
  <div className='waiting-room' >
      <AutoChatGenerator/>

    <div className='hud no-transparent'>
      <div className="info-panel-right">
        <div className="faction-logo">
        </div>
        <p className="cyan-highlight">ally: 3 </p>
        <small>Hyperion III</small>
        <small>support</small>
        <div className="text-stats-right">
          <p><span style={{color: "cyan"}}>x ■ ■ </span> 
          <span>hp</span></p>
          <p>x ■ ■ ■  ep</p>
          <p>■ ■  sp</p>
          <p>■ ■ ■  ai</p>
        </div>
      </div>

      <div className="info-panel-left">
        <div className="faction-logo">
        </div>
        <p className="cyan-highlight">player</p>
        <small>Hyperion III</small>
        <small>support</small>
        <div className="text-stats">
          <p>hp <span style={{color: "cyan"}}>■ ■ ■ </span></p>
          <p>ep ■ ■ ■ ■ ■ x </p>
          <p>sp ■ </p>
          <p>ai ■ ■ ■ ■  </p>
        </div>
      </div>

      <div className="commands-panel">
        <small>squadron data:</small>
        <p className="command">- id:24445d1<small>[ally]</small></p>
      </div>

    <div className='modal-list'style={{ display: acceptModal ? 'flex' :'none', zIndex: '13'}}>  
    <div className='btns'>
      <div className='back-btn' onClick={ ()=>setAcceptModal(0)}> x back</div>
    </div>

    </div>
    {
      radarActive?    
      <div className='order-btn inactive'>
        <a href={"/battle?hash_id="+hash_id}><h2>WAITING</h2></a>
      </div>
      :
      <div className='order-btn' onClick={()=>setRadarActive(true)}>
        <h2>INTERCEPT</h2>
      </div>
    }
    </div>
  </div>
  </>
  //      <axesHelper />

  )
}
