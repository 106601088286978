import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import {setShowShipCardId} from '../../slices/showShipCardIdSlice';

//import items from '../utils/items';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

interface ShipCardProps {
  ship: any /*change:boolean, equip_id:number,*/ 
  }
    
const ShipCard: React.FC<ShipCardProps> = ({ ship}) =>{
  const dispatch = useDispatch();

  const [hullIsLoaded, setHullIsLoaded] = useState(false);

  const available_items = useSelector((state:RootState)=> state.availableItems);
  //const available_equips :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_hulls :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_crews :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  const available_equips = available_items?.filter((el:any) => el.item_type === "module")
  const available_hulls = available_items?.filter((el:any) => el.item_type === "hull")
  const available_crews = available_items?.filter((el:any) => el.item_type === "crew")
  const screenTab = useSelector((state:RootState)=> state.screenTab);
  

  const hangar_items = ship?.hangar_items
  const hull_equiped = ship?.equips?.find((el:any) => el.item_type === "hull")
  const modules_equiped = ship?.equips?.filter((el:any) => el.item_type === "module")
  const crews_equiped = ship?.equips?.filter((el:any) => el.item_type === "crew")
  const navigator_equiped = ship?.equips?.filter((el:any) => el.item_type === "navigator")[0]
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');


  //console.log("available_crews", available_crews)

  //function getItemJson(card_id:number, item_type:string){
  //  return available_items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  
  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "■ ".repeat(square_num)
        //return <div className='square-indicator'></div>
        return(<>
        {[...Array(square_num)].map((e, i) => <div className='square-indicator'></div>)}
        </>)
    }
    return ""
  }

  function crossIndicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "x ".repeat(square_num)
        //return <div className='square-indicator-empty'></div>
        return(<>
          {[...Array(square_num)].map((e, i) => <div className='square-indicator-empty'></div>)}
          </>)
    }
    return ""
  }


  return (
    <>      

    <div className='general-inventory'>


    <div className='items dotted-background appear-with-shift'>


        <div className='info-panel-left not-fixed '>
          <div className='voyage-stats-list  '>

            <p  
            className='stat short-wrapable main-color'
            >
              {hull_equiped?.name}
            </p>
            <p className='inactive'>{hull_equiped?.stats?.ship_class}</p>
          </div>
          
        </div>

        

        <div className='info-panel-right not-fixed '>
            <div className='voyage-stats-list right '>

                {navigator_equiped ?
                  <div 
                  className='stat short-wrapable main-color'  
                  >{navigator_equiped?.name}</div>
                  :
                  <div className='stat main-color'>
                  --
                  </div>
                }
                  <div className='stat'><p className='inactive'>судьба</p></div>
            </div>

        </div>

        
        <div className='info-left '>
          <div className='voyage-stats-list right cut-content '>
              <p className='stat'><span className='inactive'>модули {modules_equiped?.length}/{ship?.total_stats?.modules_capacity} </span></p>

              { modules_equiped?.map((eq:any) => {
                return (
                  <p className='stat main-color'>
                    {eq?.name}
                  </p>
                ) 
                }
              )}
              

          </div>
          
        </div>

        <div className='info-right  '>


            <div className='voyage-stats-list  cut-content'>
            <p className='stat'><span className='inactive'>экипажи {crews_equiped?.length}/{ship?.total_stats?.crew_capacity} </span></p>

              { crews_equiped?.map((eq:any) => {
                      return (
                        <p className='stat main-color'>
                          {eq?.name}
                        </p>
                      ) 
                      }
                    )} 
              

              </div>
        </div>

        

        <div className='hull' >
        <div className='circle'/>
          <img 
          src={hull_equiped?.img} 
          className={hullIsLoaded ? 'img-float-animation' : 'zero-opacity'}
          onLoad={() => setHullIsLoaded(true)}
          alt="Loaded content"/>
          </div>
        
    

      </div>
      
      
      
      </div>

      <div className='hud no-opacity'>
        <div className="commands-panel">
         
         <div className="command-order-btns-row">
           <div className="secondary-order-btn" onClick={() => dispatch(setShowShipCardId(0))}>НАЗАД</div>
         </div>

        </div>
      </div>

    </>
    //      <axesHelper />

  )
}

export default ShipCard