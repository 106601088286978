import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import {setShowShipCardId} from '../../slices/showShipCardIdSlice';

//import items from '../utils/items';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const path_width = 100
const path_height = 100
const event_cropped_height = 80

const padding = 10

interface RadarWaveProps {
  karma: any /*change:boolean, equip_id:number,*/ 
  }
    
const RadarWave: React.FC<RadarWaveProps> = ({karma}) =>{
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');

  const Width = 500
  const Q_of_lines = 41
  let karma_coef = 1
  const lines = Array.from({ length: Q_of_lines }, (_, index) => 2 + index * Math.floor(Width/Q_of_lines));
  let duration = 1
  let shrinkTo = 1
  // Helper function to generate random animation duration and shrink values
  const getRandomAnimation = () => {
    duration = Math.random()/3 + 0.4 ; // Random duration between 1s and 3s
    shrinkTo = 0; // Random shrink point between 50 and 100

    return [
    {
      animation: `shrinkAnimation ${duration}s ease-in-out infinite`,
      '--shrink-to': shrinkTo,
    },
    {
      animation: `shrinkAnimation ${duration}s ease-in-out 0.11s infinite`,
      '--shrink-to': shrinkTo,
    },
    ]
  };

  return (
    <div className='radar-waves-box' key="memo-radar">

        <svg width="100%" height="100%" viewBox="0 0 500 200" preserveAspectRatio="xMidYMid meet">
          <g id="radar-wave-1"  strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" key='radar-waves'>
      
          {lines.map((x, index) => {
            let r_a = getRandomAnimation()
            let y1 =  Math.random() * 70 + Math.sin(index/3)*30 *karma_coef
            let y2 = y1 + 90 + Math.random() * 30
            return(
            <g key={"g"+index + Math.random()}>
              <line
                key={"a"+index + Math.random()}
                x1={x}
                y1={y1}
                x2={x}
                y2={y2}
                style={r_a[0]}
                className="animated-line"
              />
              <line
                key={"b"+index  + Math.random()}
                x1={x+6}
                y1={y1+ 20}
                x2={x+6}
                y2={y2+ 20}
                style={r_a[1]}
                className="animated-line second-line"
              />
          </g>)
          })}
          </g>
        </svg>
    </div>

  );

}


export default RadarWave


