import React, { useRef, useState, useEffect} from 'react';
import ky from 'ky';
import EquipCard from '../components/inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
//import items from '../utils/items';
import {setUser} from '../slices/userSlice';
import {setAvailableEvents} from '../slices/availableEvents';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;



export default function Inventory() {
  const dispatch = useDispatch();
  const [equipModal, setEquipModal] = useState(0)
  const [changeItemTargetId, setChangeItemTargetId] = useState(0)

  const user = useSelector((state:RootState)=> state.user);
  const available_events = useSelector((state:RootState)=> state.availableEvents);
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');

  //function getItemJson(card_id:number, item_type:string){
  //  return available_items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  useEffect(() => {

   fetchAvailableEvents();
  }, [dispatch]);

  const start_events = available_events?.filter((el:any) => el.card_type === "start").sort((a, b) => a.dive_level < b.dive_level ? 1 : -1)
  const not_start_events = available_events?.filter((el:any) => el.card_type != "middle" && el.card_type != "start"  && el.card_type != "delayed_start")

  const sorted_events = start_events.concat(not_start_events)

  async function fetchAvailableEvents() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/events/get_available_events?hash_id=${hash_id}`;

      const result :any = await ky.get(requestUrl, { credentials: 'include' }).json();
      dispatch(setAvailableEvents(result));
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  console.log('available_events',available_events)

  function eventView(event:any, is_delayed:boolean) {
    return(
      <>
        <div className='event'>
          <div className={event.consequences.length ? (is_delayed ? 'description is_delayed' : 'description') : 'description no-after-and-before'}>
            <div className='hidden-text'>
            <p>
              {
                is_delayed ?
                <>
                <span className='cyan-highlight'>⠀{event?.dive_level}⠀</span>
                  <a className='' href={'http://185.96.163.17:8000/admin/gameplay/event/'+ event.id +'/change/'}>
                  ⠀{event.card_type}
                  </a>
                  <span>⠀{event.name}⠀</span>
                </>
                :
                <a href={'http://185.96.163.17:8000/admin/gameplay/event/'+ event.id +'/change/'}>
                  {event.card_type}-{event.id}⠀
                </a>
              }
              {event.description}
            </p>

            </div>
          </div>
          <div className='consequences'>
            {event.consequences?.map((cons:any, index: number) => {
                let inner_event = null 
                let delayed_event = null 
                if (cons.consequence){
                  inner_event = available_events?.find((el:any) => el.id === cons.consequence)
                }
                if (cons.delayed_consequence){
                  delayed_event = available_events?.find((el:any) => el.id === cons.delayed_consequence)
                }
                return(
                  <div className={index != (event.consequences).length - 1 ? 'consequence' : 'consequence consequence-last'}>
                    <div className='title'>
                      <a href={'http://185.96.163.17:8000/admin/gameplay/consequence/'+ cons.id +'/change/'} className='highlight'>cons_id:{cons.id}</a>
                      <div>{cons.name}</div>
                    </div>
                    <div className={inner_event && inner_event?.consequences?.length || delayed_event && delayed_event?.consequences?.length ?  'description' : 'description no-after-and-before'}>
                        {cons?.stats?.cost ? <div className='inactive' >cost: {JSON.stringify(cons?.stats?.cost)}</div> :<></>}
                        {cons?.stats?.cost_per_ship ? <div className='inactive'>per_ship: {JSON.stringify(cons?.stats?.cost_per_ship)}</div> :<></>}
                        {cons?.stats?.requirements ? <div className='inactive'>req: {JSON.stringify(cons?.stats?.requirements)}</div> :<></>}

                      </div>
                      {inner_event? eventView(inner_event, false) : <></>}
                      {delayed_event? eventView(delayed_event, true) : <></>}
                    </div>
                )
              }
            )}
          </div>
        </div>
      </>
    )
  };

  return (
    <>
      <div className='hud'>
        <div className='event-list-commands'>
          <a href={'http://185.96.163.17:8000/admin/gameplay/event/'} className='highlight'>⠀ВСЕ ИВЕНТЫ⠀</a>
          <a href={'http://185.96.163.17:8000/admin/gameplay/event/add'} className='highlight'>⠀+ ИВЕНТ⠀</a>
          <a href={'http://185.96.163.17:8000/admin/gameplay/consequence/add'} className='highlight'>⠀+ ПЕРЕХОД⠀</a>
        </div>
      </div>

    <div className='events-list'>
      <div className='top-stats'>
        <div className='grey-highlight'>V: {start_events?.filter((el:any) => el.dive_level == 5).length}</div>
        <div className='grey-highlight'>IV: {start_events?.filter((el:any) => el.dive_level == 4).length}</div>
        <div className='grey-highlight'>III: {start_events?.filter((el:any) => el.dive_level == 3).length}</div>
        <div className='grey-highlight'>II: {start_events?.filter((el:any) => el.dive_level == 2).length}</div>
        <div className='grey-highlight'>I: {start_events?.filter((el:any) => el.dive_level == 1).length}</div>
        <div className='grey-highlight'>0: {start_events?.filter((el:any) => el.dive_level == 0).length}</div>
        <div className='grey-highlight'>Спец.: {not_start_events.length}</div>
        <div className='grey-highlight'>Сумм: {sorted_events.length}</div>
      </div>
      {
        sorted_events?.map((start_e:any) => (
          <div className='consequence no-after-and-before'>
              <div className='title '>
              <p className='cyan-highlight'>⠀{start_e?.dive_level}⠀</p>
                <p className=''>{start_e?.name}</p>
              </div>
              {eventView(start_e, false)}
          </div>
        ))
      }
    </div>
    
    </>

  )
}
